import styled from 'styled-components'
import propTypes from '@styled-system/prop-types'
import { animated as a } from 'react-spring'

import {
  zIndex,
  system,
  compose,
  display,
  flex,
  borderRadius,
  overflow,
  border,
  boxShadow,
  borderColor,
  justifySelf,
  alignSelf,
  space,
  layout,
  color,
  fontSize,
  order,
  background,
} from 'styled-system'

const config = {
  bblr: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },
  bbrr: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },
  btlr: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },
  btrr: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },
}

export const borderRadiuses = system(config)

const boxProps = compose(
  zIndex,
  fontSize,
  order,
  space,
  layout,
  color,
  background,
  border,
  borderColor,
  display,
  borderRadius,
  borderRadiuses,
  overflow,
  boxShadow,
  justifySelf,
  alignSelf,
  flex
)
const Box = styled('div')(boxProps)

export const AniBox = a(Box)

Box.propTypes = {
  ...propTypes.fontSize,
  ...propTypes.order,
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.background,
  ...propTypes.border,
  ...propTypes.borderColor,
  ...propTypes.display,
  ...propTypes.borderRadius,
  ...propTypes.borderRadiuses,
  ...propTypes.overflow,
  ...propTypes.boxShadow,
  ...propTypes.justifySelf,
  ...propTypes.alignSelf,
  ...propTypes.flex,
}
Box.defaultProps = {
  // mx: 'auto',
}
Box.displayName = 'Box'
export default Box
