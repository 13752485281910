import css from '@styled-system/css'
import PropTypes from 'prop-types'
import React, { createRef, useState } from 'react'
import { animated, useSpring, to, config } from 'react-spring'
import styled from 'styled-components'
import Portal from '../../helpers/Portal'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import ScrollableLink from '../ScrollableLink'
import { Box, Flex, Heading, Icon, Text, AniBox } from '../UI'
import FullInstrument from './FullInstrument'
import PreviewInstrument from './PreviewInstrument'
import getResponsiveProps from '../../helpers/getResponsiveProps'
import numberWithCommas from '../../helpers/numberWithComma'
import getPriceString from '../../helpers/getPriceString'

// the components in this file needs to broken up

export const HeroImage = styled(PreviewCompatibleImage)`
  display: block !important;
  /* border-bottom-left-radius: 0;
  border-top-right-radius: 6px; */
  .img-string-src {
    display: block;
    object-fit: cover;
    border-radius: inherit;
    min-height: 200px;
    /* border-top-left-radius: ${props => props.theme.radii[1]};
    border-top-right-radius: ${props => props.theme.radii[1]};
    border-bottom-left-radius: 0px; */
    max-height: inherit;
  }
  @media screen and (max-width: 699px) {
    width: 100% !important;
  }
  @media screen and (min-width: 700px) {
    .img-string-src {
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 6px;
    }
  }
`

const Price = ({ children }) => (
  <Box>
    <Text
      lineHeight={0}
      m={0}
      fontWeight={900}
      color="contrastSecondary"
      fontSize={0}
    >
      {children}
    </Text>
  </Box>
)

const PreviewButton = styled(Box)`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  transition: opacity 200ms 50ms ease;
  &:focus {
    opacity: 1;
  }
`
const InstrumentContainer = styled(Box)`
  &:hover ${PreviewButton} {
    opacity: 1;
  }
`
const CardContainer = styled(Flex)`
  border-radius: 6px;
  position: relative;
  height: 100%;
  transition: background-color 300ms ease;
  ${css({
    '&:hover': {
      bg: 'lightergrey',
    },
  })}

  /* &:hover {
    background-color: ${props => props.theme.colors.lightergrey};
  } */
  `

const Instrument = React.memo(
  ({ instrument, fullPage, vertical, showPreview, boxProps }) => {
    const [expanded, setExpanded] = useState(fullPage)
    const [imageExpanded, setImageExpanded] = useState(false)

    const [props, set] = useSpring(() => ({
      opacity: 1,
      xy: [0, 0],
    }))
    const [expandPreview, setExpandPreview] = useSpring(() => ({
      opacity: 0,
      xy: [-1, -2],
    }))

    const [expandHero, setExpandHero] = useSpring(() => ({
      opacity: 1,
      xys: [0, 0, 1],
    }))

    // these should be in full instrument
    // but it's a class and idk if I want to
    // use non-hooks springs?
    const [imageProps, imageSet] = useSpring(() => ({
      xy: [0, 0],
      opacity: 1,
      config: config.gentle,
    }))

    const [imagePropsInverse, imageSetInverse] = useSpring(() => ({
      xy: [-25, -25],
      opacity: 0,
      config: config.slow,
    }))

    const toggleInstrument = () => {
      setExpandPreview({
        xy: expanded ? [-1, -2] : [0, 0],
        opacity: expanded ? 0 : 1,
      })
      set({
        xy: expanded ? [0, 0] : [2, 1],
      })
      setExpanded(expanded => !expanded)
    }

    const openInstrument = () => {
      setExpandHero({
        opacity: 0,
        xys: [-5, -5, 1.015],
      })
    }
    const expandImage = () => {
      setImageExpanded(imageExpanded => !imageExpanded)
      imageSet({
        xy: imageExpanded ? [0, 0] : [-25, -25],
        opacity: imageExpanded ? 1 : 0,
      })
      imageSetInverse({
        xy: !imageExpanded ? [0, 0] : [-25, -25],
        opacity: !imageExpanded ? 1 : 0,
      })
    }
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      // adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1420,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },

        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    const {
      title,
      subTitle,
      slug,
      price,
      type,
      slider,
      initialSlide,
      heroImage,
      id,
    } = instrument
    const instPrice = getPriceString(price)

    return (
      <animated.div
        style={{
          transform: props.xy.to((x, y) => `translate3d(${x}%,${y}%,0)`),
          opacity: props.opacity,
          height: '100%',
          // zIndex: expanded ? 2 : 1,
          position: 'relative',
          // top: 0,
          // left: 0,
          // maxWidth: 1150,
          // borderRadius: expanded && !fullPage && 25,
        }}
        // css={css({
        //   backgroundColor: expanded && !fullPage && 'primary.0',
        // })}
      >
        <InstrumentContainer
          borderRadius={6}
          boxShadow={{
            xs: fullPage ? null : 'small',
            md: expanded ? 'large' : 'page',
          }}
          bg={'white'}
          style={{ height: '100%' }}
          m={0}
        >
          {showPreview && (
            <React.Fragment>
              {!fullPage && (
                <PreviewButton
                  className="inset-focus"
                  bg={'primary.0'}
                  css={css({
                    '&:hover': {
                      backgroundColor: 'primary.1',
                    },
                  })}
                  as="button"
                  onClick={toggleInstrument}
                  display={{ _: 'none', lg: 'block' }}
                  bbrr={1}
                  btlr={'inherit'}
                  px={1}
                  py={'xs'}
                  border="none"
                  aria-label="Preview instrument"
                >
                  <Text as="span" color="white">
                    <Icon name="expand" small></Icon>
                  </Text>
                </PreviewButton>
              )}

              {!fullPage && expanded && (
                <Portal>
                  {/* the preview backdrop */}
                  <AniBox
                    tabIndex={0}
                    p={2}
                    m={'-2%'}
                    overflowY={'scroll'}
                    id={`inst-expand-bg-portal-${id}`}
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.6',
                      transform: expandPreview.xy.to(
                        (x, y) => `translate3d(${x}%,${y}%,0)`
                      ),
                      opacity: expandPreview.opacity,
                      visibility: expandPreview.opacity.to(o =>
                        o === 0 ? 'hidden' : 'visible'
                      ),
                      zIndex: expandPreview.opacity.to(o => (o === 0 ? 0 : 1)),
                    }}
                  >
                    {!fullPage && expanded && (
                      <PreviewInstrument
                        initialSlide={1}
                        id={id}
                        expandPreview={expandPreview}
                        imagePropsInverse={imagePropsInverse}
                        imageProps={imageProps}
                        imageExpanded={imageExpanded}
                        expandImage={expandImage}
                        fullPage={true}
                        preview={!fullPage}
                        instrument={instrument}
                        toggleInstrument={toggleInstrument}
                      />
                    )}
                  </AniBox>
                </Portal>
              )}
            </React.Fragment>
          )}
          {fullPage && expanded ? (
            <FullInstrument
              initialSlide={1}
              imagePropsInverse={imagePropsInverse}
              imageProps={imageProps}
              imageExpanded={imageExpanded}
              expandImage={expandImage}
              fullPage={true}
              preview={!fullPage}
              instrument={instrument}
              toggleInstrument={toggleInstrument}
            />
          ) : (
            <ScrollableLink
              onClick={openInstrument}
              style={{
                display: 'block',
                height: '100%',
                borderRadius: 'inherit',
              }}
              to={`/instrument/${slug}`}
              // exitProps={{ delay: 1, length: 1 }}
            >
              <CardContainer
                // flexWrap={vertical ? 'no-wrap' : 'wrap'}
                flexDirection={
                  vertical
                    ? getResponsiveProps(vertical, 'column', 'row')
                    : 'row'
                }
              >
                <Box
                  flex={
                    vertical
                      ? getResponsiveProps(vertical, 1, 6)
                      : { _: 6, md: 4, lg: 6 }
                  }
                >
                  {/* bit of a mess */}

                  <animated.div
                    style={{
                      transform: expandHero.xys.to(
                        (x, y, s) => `translate3d(${x}%,${y}%,0) scale(${s})`
                      ),
                      // opacity: expandHero.opacity,
                    }}
                  >
                    <HeroImage
                      btlr={6}
                      bblr={
                        vertical ? getResponsiveProps(vertical, '0px', 6) : 6
                      }
                      btrr={
                        vertical
                          ? getResponsiveProps(vertical, 6, '0px')
                          : '0px'
                      }
                      vertical={vertical}
                      css={css({
                        maxHeight: [
                          '200px',
                          '230px',
                          '350px',
                          '420px',
                          '320px',
                        ],
                      })}
                      styles={
                        vertical
                          ? {
                              width: '100%',
                              maxHeight: 250,
                              objectFit: 'contain',
                            }
                          : {
                              width: '100%',
                              // make height higher than maxheight
                              // so maxheight prevails
                              height: '500px',
                              // minHeight: '350px',
                              // maxHeight: '300px', // wut
                            }
                      }
                      imgStyle={{ objectPosition: '50% 80%' }}
                      width={1}
                      // minHeight={280} // magic
                      imageInfo={
                        heroImage.file
                          ? { image: `${heroImage.file.url}?q=50&h=420` }
                          : { fixed: heroImage.fixed }
                      }
                    />
                  </animated.div>
                </Box>
                <Box p={vertical ? 2 : { _: 2, lg: 3 }} flex={8} {...boxProps}>
                  <animated.div
                    style={{
                      transform: expandHero.xys.to(
                        (x, y, s) => `translate3d(${x}%,${y}%,0) scale(${s})`
                      ),
                      // opacity: expandHero.opacity,
                    }}
                  >
                    <Flex height="100%" display="flex" flexDirection="column">
                      <Text fontWeight={600} mb={0} color="lightgreyAA">
                        {type.title}
                      </Text>

                      <Heading
                        fontWeight={600}
                        mb={'xs'}
                        color="primary.1"
                        fontSize={{ _: 2, sm: 3 }}
                      >
                        {title}
                      </Heading>

                      {subTitle && (
                        <Text
                          fontWeight={600}
                          fontSize={0}
                          color="contrastSecondary"
                        >
                          {subTitle}
                        </Text>
                      )}
                      <Box mt="auto" alignSelf="flex-start">
                        <Price>{instPrice}</Price>
                      </Box>
                    </Flex>
                  </animated.div>
                </Box>
              </CardContainer>
            </ScrollableLink>
          )}
        </InstrumentContainer>
      </animated.div>
    )
  }
)
Instrument.propTypes = {
  fullPage: PropTypes.bool,
  instrument: PropTypes.object.isRequired,
  showPreview: PropTypes.bool,
}
Instrument.defaultProps = {
  fullPage: false,
  showPreview: true,
}
export default Instrument
