import React from 'react'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '../'

const FaIcon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'flexDirection',
})`
  margin-right: ${props =>
    props.children &&
    props.flexDirection === 'row' &&
    `${props.theme.space[1]}px`};
  margin-left: ${props =>
    props.children &&
    props.flexDirection === 'row-reverse' &&
    `${props.theme.space[1]}px`};
  /* transition: all 300ms ease; */
  g > g[transform] {
    transition: transform 300ms ease;
  }
`

const Icon = ({
  name,
  children,
  flexDirection,
  iconSize,
  transform,
  spin,
  pulse,
  ...rest
}) => (
  <Box as="span" style={{ lineHeight: 0 }} {...rest}>
    <FaIcon
      icon={
        // uses string fab,facebook so
        // it can be mapped to an array
        // as required by FA: ['fab', 'facebook']
        typeof name === 'string' && name.split(',')[1]
          ? [name.split(',')[0], name.split(',')[1]]
          : name
      }
      children={Boolean(children)}
      flexDirection={flexDirection}
      size={iconSize}
      transform={transform}
      spin={spin}
      pulse={pulse}
    />
  </Box>
)

export default Icon
