import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import { Text, Icon, Flex } from '../../UI'
import ScrollableLink from '../../ScrollableLink'
import { useSpring, animated as a } from 'react-spring'
import useKeyPress from '../../../helpers/useKeyPress'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

// this file includes the SubMenu component

const Item = styled(ScrollableLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !prop.startsWith('_css'),
})`
  white-space: nowrap;
  color: ${props => props.theme.colors.white};
  /* ipad fix */
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  transition: color 250ms ease;
  position: relative;

  &.active {
    color: ${props => props.theme.colors.secondary[0]};
  }
`
const TopLevelListItem = styled(Text)`
  position: relative;
  line-height: 1;
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.secondary[2]};
    background-color: ${props => props.theme.colors.primary[1]};
    transition: all 250ms ease;
    /* .nav__sub-menu {
      animation: fadeIn 1s ease;
      display: block;
    } */
  }
  display: block;
  .nav__sub-menu {
    overflow: hidden;
    position: absolute;
    min-width: 100%;
    z-index: 0;
    border-bottom-right-radius: ${props => props.theme.radii[1]};
    border-bottom-left-radius: ${props => props.theme.radii[1]};
    padding: 0;
    position: absolute;
    top: 100%;
    /* padding-top: 0.5rem; */
    left: 0;
    background: ${props => props.theme.colors.primary[1]};
    .nav__sub-menu-item {
      display: block;
      /* &:first-of-type {
        margin-top: 0.5rem;
      } */
      &:last-of-type {
        margin-bottom: 0.5rem;

        border-bottom-right-radius: ${props => props.theme.radii[1]};
        border-bottom-left-radius: ${props => props.theme.radii[1]};
      }
      &:hover,
      &:focus {
        background-color: ${props => props.theme.colors.primary[0]};
      }
    }
    ${Item} {
      color: ${props => props.theme.colors.secondary[1]};
      display: block;
      text-align: left;
      overflow: hidden;
      text-transform: none;
    }
  }
`

const MenuItem = React.memo(
  ({ icon, label, path, subMenu, mode, ...props }) => {
    const [showSubMenu, setShowSubMenu] = useState()
    const show = () => setShowSubMenu(true)
    const hide = () => setShowSubMenu(false)

    // listen for tab on menu items that have a subMenu only
    const shiftTab = subMenu && useKeyPress(['Shift', 'Tab'])

    const onFocusOutLast = () => {
      !shiftTab && hide()
    }
    const onFocusOutFirst = () => {
      shiftTab && hide()
    }

    return (
      <TopLevelListItem
        fontSize={1}
        fontWeight={700}
        letterSpacing={0.5}
        as={mode === 'touch' ? 'span' : 'li'}
        onTouchStart={e => e.preventDefault()}
        onMouseEnter={mode !== 'touch' ? show : undefined}
        onMouseLeave={mode !== 'touch' ? hide : undefined}
        className="nav__menu-item has-dark-background"
      >
        <Flex flex={1} flexDirection="row">
          <Item
            css={css({
              py: 2,
              pr: subMenu ? [2, 2, 2, 0] : [2, 2, 2, 1],
              pl: 1,
            })}
            className="inset-focus"
            type="navLink"
            to={path}
            {...props}
            aria-haspopup="true"
            aria-expanded={showSubMenu}
            css={css({
              'padding-right': subMenu && 6.25,
            })}
          >
            {icon && <Icon name={icon} />}
            {label}
          </Item>
          {subMenu && (
            <Item
              css={css({
                // marginLeft: 'xs',
                py: 2,
                pr: [2, 2, 2, 2, 1],
                pl: [2, 2, 2, 2, 'xs'],
                background: 'inherit',
                outline: 'none',
                border: 'none',
                textAlign: 'center',
              })}
              as="button"
              aria-label="Toggle sub menu"
              aria-expanded={showSubMenu}
              onClick={() => setShowSubMenu(!showSubMenu)}
              className="inset-focus"
            >
              <Icon
                fontSize={10}
                name={'chevron-down'}
                transform={showSubMenu ? 'shrink-1 rotate-180' : 'grow-1'}
              />
            </Item>
          )}
        </Flex>
        {subMenu && subMenu.menuItems.length && (
          <SubMenu showSubMenu={showSubMenu} mode={mode}>
            {subMenu.menuItems.map(({ label, path, icon, ...sub }, i) => (
              <Text
                key={label}
                as="li"
                className="nav__sub-menu-item"
                onBlur={
                  i === 0
                    ? onFocusOutFirst
                    : i === subMenu.menuItems.length - 1
                    ? onFocusOutLast
                    : undefined
                }
              >
                <Item
                  css={css({ py: 1, px: 2 })}
                  type="navLink"
                  to={path}
                  className="inset-focus"
                  {...sub}
                >
                  {icon && <Icon name={icon} />}

                  {label}
                </Item>
              </Text>
            ))}
          </SubMenu>
        )}
      </TopLevelListItem>
    )
  }
)
const SubMenu = ({ showSubMenu, mode, style, children, ...rest }) => {
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver })

  const subMenuFrom = {
    height: mode === 'touch' ? 0 : height / 2,
    transform: `scale(1)`,
    pointerEvents: 'none',
    vis: 0,
    boxShadow: mode === 'touch' ? undefined : '0px 0px 0px rgba(0,0,0,.065)',
    transformOrigin: 'top',
  }
  const subMenuTo = {
    height: showSubMenu ? height : mode === 'touch' ? 0 : height / 4,
    transform: showSubMenu ? `scale(1)` : `scale(0.92)`,
    transformOrigin: 'top',
    pointerEvents: showSubMenu ? 'auto' : 'none',
    vis: showSubMenu ? 1 : 0,
    boxShadow: mode === 'touch' ? undefined : '0px 9.5px 12px rgba(0,0,0,.035)',

    // marginBottom: showSubMenu ? 25 : 0,
  }

  const [subMenuAnimation, set, stop] = useSpring(() => subMenuFrom)
  useEffect(() => {
    showSubMenu ? set(subMenuTo) : set(subMenuFrom)
  }, [showSubMenu])

  useEffect(() => {
    return () => {
      stop()
    }
  }, [])
  // const subMenuAnimation = useSpring({
  //   from: {
  //     height: mode === 'touch' ? 0 : height / 2,
  //     transform: `scale(1)`,
  //     pointerEvents: 'none',
  //     vis: 0,
  //     boxShadow: '0px 0px 0px rgba(0,0,0,.065)',
  //     transformOrigin: 'top',
  //   },
  //   to: {
  //     height: showSubMenu ? height : mode === 'touch' ? 0 : height / 4,
  //     transform: showSubMenu ? `scale(1)` : `scale(0.92)`,
  //     transformOrigin: 'top',
  //     pointerEvents: showSubMenu ? 'auto' : 'none',
  //     vis: showSubMenu ? 1 : 0,
  //     boxShadow: '0px 9.5px 12px rgba(0,0,0,.035)',

  //     // marginBottom: showSubMenu ? 25 : 0,
  //   },
  // })
  return (
    <a.div
      style={{
        ...subMenuAnimation,
        visibility: subMenuAnimation.vis.to(x =>
          x > 0 ? 'visible' : 'hidden'
        ),
        opacity: subMenuAnimation.vis.to([0, 0.6, 1], [0, 1, 1]),
      }}
      className="nav__sub-menu"
    >
      <ul ref={ref} {...rest}>
        {children}
      </ul>
    </a.div>
  )
}

export default MenuItem
