import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { animated as a, useSprings, config } from 'react-spring'
const flourishPaths = [
  `M 267.9 58 
    C 267.9 58 267.9 58 267.9 58 
    C 268.4 59.1 267.5 59.1 266.7 59.2 
    C 265.8 59.2 262.7 57.8 253.2 51.4 
    C 243.4 44.5 230.6 31.8 224.8 24.5 
    C 217.5 15 221.5 8.2 221.5 8.2 
    C 223.8 3.6 228.6 0.9 233.7 1.2 
    C 243.5 1.5 248.4 5 252.5 7.4 
    C 253.8 7.9 257.5 10.6 255.6 11.2 
    C 252.4 12.3 249.7 14.7 248.2 17.7 
    C 246.3 21.3 246.1 25.4 248.3 31 
    C 248.617 31.833 248.957 32.654 249.318 33.46 
    C 249.678 34.267 250.059 35.059 250.457 35.837 
    C 250.855 36.615 251.269 37.378 251.696 38.125 
    C 252.124 38.872 252.564 39.604 253.015 40.319 
    C 253.465 41.033 253.925 41.731 254.391 42.412 
    C 254.857 43.093 255.329 43.756 255.804 44.4 
    C 256.278 45.044 256.756 45.67 257.232 46.277 
    C 257.708 46.883 258.184 47.47 258.654 48.037 
    C 259.125 48.604 259.592 49.15 260.05 49.675 
    C 260.508 50.2 260.959 50.704 261.397 51.185 
    C 261.836 51.667 262.263 52.126 262.675 52.562 
    C 263.088 52.998 263.485 53.411 263.863 53.8 
    C 264.241 54.189 264.601 54.554 264.939 54.894 
    C 265.276 55.233 265.592 55.548 265.882 55.837 
    C 266.171 56.126 266.435 56.389 266.67 56.625 
    C 266.905 56.861 267.111 57.07 267.284 57.252 
    C 267.457 57.433 267.597 57.587 267.701 57.712 
    C 267.805 57.837 267.872 57.933 267.9 58`,
  `M 287.9 114.5 
    C 287.783 114.617 287.644 114.678 287.491 114.696 
    C 287.338 114.715 287.17 114.691 286.996 114.637 
    C 286.822 114.583 286.642 114.5 286.462 114.4 
    C 286.283 114.3 286.106 114.183 285.937 114.063 
    C 285.769 113.943 285.609 113.819 285.467 113.704 
    C 285.325 113.589 285.2 113.483 285.1 113.4 
    C 284.8 113.15 284.425 112.775 283.8 112.162 
    C 283.175 111.55 282.3 110.7 281 109.5 
    C 270.7 100.6 257.1 91.5 246.4 87 
    C 232.8 80.1 206.8 74.3 182.1 82 
    C 154 90.8 132 107.5 106.5 122.5 
    C 79.9 138.8 58.4 141.8 40.6 136.5 
    C 22.1 129.9 8.8 116.2 4.6 110.3 
    C 0.4 104.4 0 100.9 0 100.9 
    C 0 100.9 0 99.7 1.9 100.1 
    C 7.8 103.4 14.9 106.8 29 107.2 
    C 29 107.2 29 107.2 29 107.2 
    C 45.1 107.3 52.5 103.4 59.8 101.3 
    C 67.1 99.2 96.1 84.2 96.1 84.2 
    C 96.1 84.2 120.5 71.1 134.1 64 
    C 143.5 59.2 153.1 55 163 51.3 
    C 172.6 47.9 182.8 46.3 193 46.6 
    C 217.5 47.7 241 56.7 260 72.2 
    C 269.9 80.5 277.9 89.7 286.1 106 
    C 286.85 107.675 287.394 109 287.773 110.056 
    C 288.153 111.112 288.369 111.9 288.462 112.5 
    C 288.556 113.1 288.528 113.513 288.42 113.819 
    C 288.313 114.125 288.125 114.325 287.9 114.5`,

  // `M 260.1 119.6
  // C 260.1 119.6 262.3 118.8 255.5 115.4
  // C 244.1 109.9 231.7 106.9 219 106.6
  // C 201.6 106.1 188.9 113.2 177.5 122
  // C 166.1 130.8 149.4 144.4 138.1 149
  // C 126.8 153.6 120.5 154.4 109.3 149.4
  // C 107.4 148.6 108.2 151.3 108.2 151.3
  // C 108.2 151.3 108.2 154.2 116.5 162.1
  // C 124.4 168.8 134.4 172.6 144.7 172.8
  // C 153.3 172.8 166.2 168 178.8 157.1
  // C 192.6 145.3 199.7 139.1 207.2 133
  // C 216.8 125.6 228.4 121.2 240.5 120.4
  // C 251.5 120 254.2 120.3 255.6 120.3
  // C 257 120.3 259.3 120.1 260.1 119.6 Z`,

  `M 260.1 119.6 
    C 260.1 119.6 262.3 118.8 255.5 115.4 
    C 249.5 112.5 243.2 110.3 236.7 108.8 
    C 230.9 107.5 225 106.7 219 106.6 
    C 214.3 106.5 209.9 106.9 205.9 107.7 
    C 202.5 108.4 199.3 109.4 196.2 110.7 
    C 194 111.6 191.8 112.7 189.7 113.8 
    C 185.4 116.2 181.4 119 177.5 121.9 
    C 175.2 123.7 172.6 125.7 169.9 127.8 
    C 168.2 129.1 166.4 130.5 164.7 131.8 
    C 161 134.6 157.2 137.4 153.5 140 
    C 148 143.8 142.7 147 138.2 148.9 
    C 134.8 150.3 131.9 151.3 129.1 152 
    C 126.1 152.7 123.3 152.9 120.3 152.6 
    C 117 152.2 113.5 151.2 109.4 149.3 
    C 107.5 148.5 108.3 151.2 108.3 151.2 
    C 108.3 151.2 108.3 154.1 116.6 162 
    C 119.6 164.5 122.8 166.6 126.3 168.3 
    C 128.9 169.6 131.7 170.6 134.5 171.3 
    C 137.9 172.2 141.3 172.7 144.8 172.7 
    C 148.6 172.7 153.2 171.8 158.2 169.8 
    C 161.6 168.5 165.3 166.6 169 164.3 
    C 172.3 162.3 175.6 159.8 178.9 157 
    C 186.4 150.6 191.9 145.8 196.6 141.8 
    C 200.5 138.4 203.9 135.6 207.3 132.9 
    C 212.1 129.2 217.4 126.2 223 124.1 
    C 228.6 122 234.5 120.7 240.6 120.3 
    C 243.6 120.2 245.9 120.1 247.8 120.1 
    C 252.9 120 254.7 120.2 255.7 120.2`,
  // C 257 120.3 259.3 120.1 260.1 119.6`
]

const Flourish = ({ flourishStyles, animate }) => {
  // 'to' paths (the final flourish form)

  // animation paths corrected with https://shapeshifter.design/
  // see ./SVG/flourishes.shapeshifter
  const [springs, set, stop] = useSprings(
    3,
    () => (
      // these are the 'from' paths (all 3 are the same a circle, all the flourishes animate out from it)
      {
        d: `
        M 281.6 108.6 
        C 281.633 108.56 281.667 108.521 281.701 108.483 
        C 281.736 108.444 281.77 108.407 281.805 108.37 
        C 281.84 108.334 281.876 108.298 281.912 108.262 
        C 281.948 108.227 281.984 108.193 282.021 108.159 
        C 282.058 108.125 282.096 108.092 282.133 108.059 
        C 282.171 108.027 282.209 107.995 282.248 107.963 
        C 282.287 107.932 282.326 107.901 282.365 107.87 
        C 282.405 107.84 282.445 107.81 282.485 107.78 
        C 282.526 107.751 282.567 107.722 282.608 107.693 
        C 282.649 107.664 282.691 107.636 282.733 107.607 
        C 282.776 107.579 282.818 107.551 282.861 107.524 
        C 282.904 107.496 282.948 107.469 282.992 107.442 
        C 283.036 107.414 283.08 107.387 283.125 107.36 
        C 283.17 107.334 283.216 107.307 283.261 107.28 
        C 283.307 107.253 283.353 107.227 283.4 107.2 
        C 284.3 106.8 285.2 106.5 286.2 106.5 
        C 287.2 106.5 288.1 106.7 288.9 107.1 
        C 289.5 107.4 290.1 107.8 290.6 108.3 
        C 292 109.6 292.7 111.2 292.7 112.9 
        C 292.7 112.9 292.7 112.9 292.7 112.9 
        C 292.7 113.9 292.5 114.9 292 115.7 
        C 291.8 116.2 291.5 116.6 291.1 117 
        C 290.6 117.5 290.1 118 289.5 118.3 
        C 288.6 118.8 287.5 119.1 286.4 119.1 
        C 284.7 119.1 283.2 118.4 282 117.3 
        C 281.3 116.6 280.8 115.8 280.4 114.9 
        C 280.2 114.2 280 113.5 280 112.8 
        C 280 111.2 280.6 109.7 281.6 108.6`,
      },
      {
        d: `M 281.6 108.6 C 281.633 108.56 281.667 108.521 281.701 108.483 C 281.736 108.444 281.77 108.407 281.805 108.37 C 281.84 108.334 281.876 108.298 281.912 108.262 C 281.948 108.227 281.984 108.193 282.021 108.159 C 282.058 108.125 282.096 108.092 282.133 108.059 C 282.171 108.027 282.209 107.995 282.248 107.963 C 282.287 107.932 282.326 107.901 282.365 107.87 C 282.405 107.84 282.445 107.81 282.485 107.78 C 282.526 107.751 282.567 107.722 282.608 107.693 C 282.649 107.664 282.691 107.636 282.733 107.607 C 282.776 107.579 282.818 107.551 282.861 107.524 C 282.904 107.496 282.948 107.469 282.992 107.442 C 283.036 107.414 283.08 107.387 283.125 107.36 C 283.17 107.334 283.216 107.307 283.261 107.28 C 283.307 107.253 283.353 107.227 283.4 107.2 C 284.3 106.8 285.2 106.5 286.2 106.5 C 287.2 106.5 288.1 106.7 288.9 107.1 C 289.5 107.4 290.1 107.8 290.6 108.3 C 292 109.6 292.7 111.2 292.7 112.9 C 292.7 112.9 292.7 112.9 292.7 112.9 C 292.7 113.9 292.5 114.9 292 115.7 C 291.8 116.2 291.5 116.6 291.1 117 C 290.6 117.5 290.1 118 289.5 118.3 C 288.6 118.8 287.5 119.1 286.4 119.1 C 284.7 119.1 283.2 118.4 282 117.3 C 281.3 116.6 280.8 115.8 280.4 114.9 C 280.2 114.2 280 113.5 280 112.8 C 280 111.2 280.6 109.7 281.6 108.6`,
      },
      {
        d: `M 281.6 108.6 C 281.633 108.56 281.667 108.521 281.701 108.483 C 281.736 108.444 281.77 108.407 281.805 108.37 C 281.84 108.334 281.876 108.298 281.912 108.262 C 281.948 108.227 281.984 108.193 282.021 108.159 C 282.058 108.125 282.096 108.092 282.133 108.059 C 282.171 108.027 282.209 107.995 282.248 107.963 C 282.287 107.932 282.326 107.901 282.365 107.87 C 282.405 107.84 282.445 107.81 282.485 107.78 C 282.526 107.751 282.567 107.722 282.608 107.693 C 282.649 107.664 282.691 107.636 282.733 107.607 C 282.776 107.579 282.818 107.551 282.861 107.524 C 282.904 107.496 282.948 107.469 282.992 107.442 C 283.036 107.414 283.08 107.387 283.125 107.36 C 283.17 107.334 283.216 107.307 283.261 107.28 C 283.307 107.253 283.353 107.227 283.4 107.2 C 284.3 106.8 285.2 106.5 286.2 106.5 C 287.2 106.5 288.1 106.7 288.9 107.1 C 289.5 107.4 290.1 107.8 290.6 108.3 C 292 109.6 292.7 111.2 292.7 112.9 C 292.7 112.9 292.7 112.9 292.7 112.9 C 292.7 113.9 292.5 114.9 292 115.7 C 291.8 116.2 291.5 116.6 291.1 117 C 290.6 117.5 290.1 118 289.5 118.3 C 288.6 118.8 287.5 119.1 286.4 119.1 C 284.7 119.1 283.2 118.4 282 117.3 C 281.3 116.6 280.8 115.8 280.4 114.9 C 280.2 114.2 280 113.5 280 112.8 C 280 111.2 280.6 109.7 281.6 108.6`,
      }
    )
  )

  const animateIn = () =>
    set(i => ({
      d: flourishPaths[i],
      delay: 325 * i,
      config: i === 2 ? config.molasses : config.molasses,
      // config: {
      //   duration: 5000
      // }
    }))

  useEffect(() => {
    animate && animateIn()
  }, [])

  return (
    <a.g className="flourish">
      {animate
        ? springs.map((data, i) => (
            <a.path
              key={i}
              d={data.d}
              style={flourishStyles ? flourishStyles[i] : {}}
            />
          ))
        : flourishPaths.map((path, i) => (
            <a.path
              key={i}
              d={path}
              style={flourishStyles ? flourishStyles[i] : {}}
            />
          ))}
      {/*   <a.path
          style={flourish1Style}
          d={path1}
        />
        <a.path
          style={flourish2Style}
          d={path2}
        />
        <a.path
          style={flourish3Style}
          d={path3}
        /> */}
    </a.g>
  )
}

Flourish.propTypes = {
  animate: PropTypes.bool,
  flourishStyles: PropTypes.array,
}

export default Flourish
