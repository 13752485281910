import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated as a, config } from 'react-spring'
import { Box } from '../UI'
import Flourish from './Flourish'

const Logo = ({ style, flourishStyles, animate, ...props }) => {
  // final paths

  return (
    <Box {...props} itemprop="logo">
      <svg
        fill={'currentColor'}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-50 0 1000 235.91"
      >
        <title>Rizzolo Guitars</title>
        <Flourish animate={animate} flourishStyles={flourishStyles} />
        <g className="rizzolo-text">
          <path d="M418.65,101.85Q433,117.8,441.33,123.32a42.92,42.92,0,0,0,18.7,6.86v6.42H412l-39.4-58.2H366.2v25.89q0,16.81,3.65,21.35t17.59,4.54v6.42H317.51v-6.42q13.5,0,17.26-4.65t3.76-21.24V36.13q0-16.82-3.65-21.36t-17.37-4.54V3.82h74.14q20.13,0,32.42,10.73t12.28,26.67a34,34,0,0,1-10.62,25.23q-10.62,10.39-28.55,12ZM406.7,41.22q0-12-7.31-19.7t-21-7.74H366.2V68.44h12.17q13.71,0,21-7.75T406.7,41.22Z" />
          <path d="M466.82,136.6v-6.42q11.07,0,14.28-4.76t3.2-21.13V97q0-16.6-3.09-21.25t-14.39-4.65V64.67l42.49-5.53v45.15q0,16.59,3.1,21.24t14.38,4.65v6.42ZM496.7,36.35A12.89,12.89,0,0,1,487,32.14,14.23,14.23,0,0,1,483,22a14,14,0,0,1,4-10,12.91,12.91,0,0,1,9.74-4.2,13.64,13.64,0,0,1,10,4.2,13.64,13.64,0,0,1,4.2,10A14.15,14.15,0,0,1,506.77,32,13.34,13.34,0,0,1,496.7,36.35Z" />
          <path d="M596.26,66.67l-33,61.08h4.2q14.38,0,18.92-2.77t6.53-10.29l1.11-4.43h4.65l-1.78,26.34H532.75l.09-3.71L566,72h-1.33q-14.62,0-19,2.65c-3,1.77-5.1,5.24-6.42,10.41l-1.11,4.42h-4.65L535,63.13h61.3Z" />
          <path d="M667.65,66.67l-33,61.08h4.2q14.39,0,18.92-2.77t6.53-10.29l1.11-4.43h4.65l-1.77,26.34H604.14l.09-3.71L637.36,72H636q-14.62,0-19,2.65c-3,1.77-5.1,5.24-6.42,10.41l-1.11,4.42H604.8l1.55-26.33h61.3Z" />
          <path d="M722.05,140.36q-18.37,0-31.43-12T677.56,99.64q0-16.59,13.06-28.55t31.43-12q18.59,0,31.64,11.73t13.06,28.77q0,16.81-13.06,28.77T722.05,140.36Zm0-70.82q-8.64,0-13.06,8.41t-4.43,21.69q0,13.27,4.43,21.8T722.05,130q8.63,0,13.16-8.63t4.54-21.69q0-13.27-4.43-21.69T722.05,69.54Z" />
          <path d="M768.19,136.6v-6.42q11.05,0,14.27-4.76t3.21-21.13V37.9q0-16.6-3.1-21.25T768.19,12V5.59L810.46,0V104.28q0,16.6,3.09,21.25t14.39,4.65v6.42Z" />
          <path d="M873.06,140.36q-18.36,0-31.42-12T828.58,99.64q0-16.59,13.06-28.55t31.42-12q18.6,0,31.65,11.73t13.06,28.77q0,16.81-13.06,28.77T873.06,140.36Zm0-70.82q-8.62,0-13,8.41t-4.43,21.69q0,13.27,4.43,21.8t13,8.52q8.64,0,13.17-8.63t4.54-21.69q0-13.27-4.43-21.69T873.06,69.54Z" />
        </g>
        <g className="guitars-text">
          <path d="M370,212.36v-2H395.3v2q-5.88,0-7.53,2t-1.64,9.21v7.3q-4.83,0-12.78,2.51T361,235.91a28.06,28.06,0,0,1-20.94-9,31.27,31.27,0,0,1,0-43.24,28,28,0,0,1,20.9-9,22.61,22.61,0,0,1,9.59,2.12,27.47,27.47,0,0,1,8.33,6.08,9.52,9.52,0,0,0,1.93-2.27,9.89,9.89,0,0,0,1.35-3.23h2v21.23h-1.93q-2-9.56-7.43-14.86a18.5,18.5,0,0,0-13.51-5.31q-10.62,0-15.83,8.16a34,34,0,0,0-5.21,18.67,32.19,32.19,0,0,0,5.36,18.58q5.36,7.88,17.51,7.87a18.24,18.24,0,0,0,11.34-3.46,11.44,11.44,0,0,0,4.59-9.7,5.45,5.45,0,0,0-2.08-4.7Q374.94,212.36,370,212.36Z" />
          <path d="M438.75,212v9.46q0,6.66-3.86,10.33a14.13,14.13,0,0,1-10.13,3.66,15.22,15.22,0,0,1-10.48-3.71q-4.18-3.72-4.19-10.28V212c0-3.47-.38-5.68-1.11-6.61s-2.46-1.4-5.17-1.4v-1.83h19V204q-4.06,0-5.17,1.4c-.74.93-1.11,3.14-1.11,6.61v9.94q0,4.64,2.51,7.05a9.19,9.19,0,0,0,6.66,2.41,9.55,9.55,0,0,0,6.76-2.41q2.59-2.42,2.6-7.05V212c0-3.47-.35-5.68-1.06-6.61s-2.41-1.4-5.11-1.4v-1.83H445V204q-4,0-5.16,1.4C439.12,206.29,438.75,208.5,438.75,212Z" />
          <path d="M457.86,234.27v-1.84q4,0,5.11-1.44c.78-1,1.16-3.19,1.16-6.66V212c0-3.47-.37-5.68-1.11-6.61s-2.46-1.4-5.16-1.4v-1.83h19.2V204q-4.05,0-5.16,1.4c-.74.93-1.11,3.14-1.11,6.61v12.36q0,5.11,1.11,6.61c.74,1,2.46,1.49,5.16,1.49v1.84Z" />
          <path d="M499.72,205q-4.92,0-6.56.87a4.86,4.86,0,0,0-2.22,3.48l-.48,1.83h-1.64l.77-9.07h35.52l.67,9.07h-1.54l-.48-1.83a5.36,5.36,0,0,0-2.18-3.48c-1-.58-3.2-.87-6.61-.87h-4.34v19.31q0,5.11,1.11,6.61c.74,1,2.46,1.49,5.16,1.49v1.84H497.79v-1.84q4,0,5.12-1.44t1.16-6.66V205Z" />
          <path d="M542.59,227a8.29,8.29,0,0,0-1.06,3.38q0,2,5.21,2v1.84H531.4v-1.84c2.31,0,3.92-.38,4.82-1.16s2-2.57,3.29-5.4l11.77-25H553l11.68,25a18.89,18.89,0,0,0,3.23,5.36c.87.8,2.36,1.2,4.49,1.2v1.84H553.79v-1.84q5.21,0,5.21-2.4a8.54,8.54,0,0,0-1-3l-1.39-3.21H544Zm7.74-16.41L545.56,221h9.59Z" />
          <path d="M597.22,220.85v3.48q0,5.11,1.11,6.61c.74,1,2.46,1.49,5.16,1.49v1.84h-19.2v-1.84c2.63,0,4.34-.48,5.11-1.44s1.16-3.19,1.16-6.66V212c0-3.47-.37-5.68-1.11-6.61S587,204,584.29,204v-1.83h17q6.09,0,9.56,2.65a8.19,8.19,0,0,1,1.06,12.5,12,12,0,0,1-6.76,3.28l4.44,4.83a30.93,30.93,0,0,0,5.46,5.06,12,12,0,0,0,5.35,2v1.84H609.57l-10.71-13.42Zm4-15.83h-4v12.84h4a5.91,5.91,0,0,0,4.63-1.83,6.6,6.6,0,0,0,1.64-4.54,7,7,0,0,0-1.54-4.63A5.85,5.85,0,0,0,601.18,205Z" />
          <path d="M644.72,235.52a12.32,12.32,0,0,1-4.87-1,14.42,14.42,0,0,1-3.81-2.27,5.55,5.55,0,0,0-1,1.4,5.06,5.06,0,0,0-.58,1.88h-1.83V222.2h2a11.54,11.54,0,0,0,3.55,7.43,10.45,10.45,0,0,0,7.3,2.71,6.38,6.38,0,0,0,4-1.21,4.14,4.14,0,0,0,1.53-3.44q0-3.39-6.18-6.29l-5.5-2.62a12.64,12.64,0,0,1-5-3.82,8.83,8.83,0,0,1-1.78-5.47,7.8,7.8,0,0,1,2.94-6.05,10.82,10.82,0,0,1,7.38-2.57,12.1,12.1,0,0,1,4.2.66,10.86,10.86,0,0,1,3.33,1.95,4.68,4.68,0,0,0,1.26-2.61h1.83v11.39h-2a11.72,11.72,0,0,0-2.55-6.15,7.28,7.28,0,0,0-5.75-2.24,6.09,6.09,0,0,0-3.66,1.11,3.38,3.38,0,0,0-1.55,2.85q0,2.91,6.66,6l5.31,2.61a12.12,12.12,0,0,1,4.82,3.92,8.33,8.33,0,0,1,1.65,4.79,9.4,9.4,0,0,1-3.43,7.41A12.34,12.34,0,0,1,644.72,235.52Z" />
        </g>
      </svg>
    </Box>
  )
}

Logo.defaultProps = {
  width: 125,
  color: 'primary.0',
}

Logo.propTypes = {
  color: PropTypes.string,
  animate: PropTypes.bool,
  flourishStyles: PropTypes.arrayOf(PropTypes.object),
}
export default Logo
