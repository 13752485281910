import styled from 'styled-components'

import { Flex } from '../'

const FullWidth = styled(Flex)`
  @media screen and (min-width: 96em) {
    margin-left: ${({ left, right, center }) =>
      center
        ? 'calc(25% - 25vw)'
        : left || (!left && !right)
        ? 'calc(50% - 50vw)'
        : 'auto'};
    margin-right: ${({ left, right, center }) =>
      center
        ? 'calc(25% - 25vw)'
        : right || (!left && !right)
        ? 'calc(50% - 50vw)'
        : 'auto'};
  }

  width: auto;
  max-width: initial;
  position: ${({ absolute }) => (absolute ? 'absolute' : 'static')};
`
FullWidth.displayName = 'FullWidth'

export default FullWidth
