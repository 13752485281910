import React, { useState } from 'react'
import Slider from '../Slider'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import { Box, Flex } from '../UI'
import styled from 'styled-components'

const SlickWrapper = styled.div`
  z-index: 1;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.colors.lightergrey};
  min-width: 0;
  min-height: 0;

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thumbs-slider .slick-track {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
  }
  .slick-slider {
    width: 0;
    min-width: 100%;
  }
`

const ImageSlider = ({
  settings,
  images,
  styles,
  imgProps,
  thumbs,
  extractImg,
  extractThumb,
}) => {
  const [currentImage, setImage] = useState()
  let mainSlider = React.createRef()

  return (
    <SlickWrapper>
      <Slider settings={settings} ref={slider => (mainSlider = slider)}>
        {images.map((image, i) => {
          const img = extractImg ? extractImg(image) : { fluid: image.fluid }
          return (
            <Flex align="center" key={i}>
              <PreviewCompatibleImage
                showLoading
                placeHolderStyle={{ backgroundColor: '#dadada' }}
                styles={{
                  display: 'block',
                  maxHeight: 'calc(100vh - 75px)',
                  margin: 'auto',
                  ...styles,
                }}
                imgStyle={{
                  objectFit: 'contain',
                }}
                imageInfo={img}
                {...imgProps}
              />
            </Flex>
          )
        })}
      </Slider>
      {thumbs && (
        <Box my={1}>
          <Slider
            className="thumbs-slider"
            settings={{
              speed: 200,
              slidesToScroll: 4,
              slidesToShow: 4,
              arrows: images.length > 3,
              infinite: false,
              // focusOnSelect: true,
              swipe: false,
              variableWidth: true,

              // responsive: [
              //   {
              //     breakpoint: 400,
              //     settings: {
              //       slidesToShow: 3,
              //       slidesToScroll: 3,
              //     },
              //   },
              //   {
              //     breakpoint: 500,
              //     settings: {
              //       slidesToShow: 3,
              //       slidesToScroll: 3,
              //     },
              //   },
              //   {
              //     breakpoint: 700,
              //     settings: {
              //       slidesToShow: 3,
              //       slidesToScroll: 3,
              //     },
              //   },
              //   {
              //     breakpoint: 1099,
              //     settings: {
              //       slidesToShow: 3,
              //       slidesToScroll: 3,
              //     },
              //   },
              //   {
              //     breakpoint: 1100,
              //     settings: {
              //       slidesToShow: 4,
              //       slidesToScroll: 4,
              //     },
              //   },
              // ],
            }}
          >
            {/* <Flex
          flexDirection="row"
          mt={1}
          width={1}
          style={{ overflowX: 'auto', touchAction: 'pan-x' }}
          pb={1}
        > */}
            {images.map((image, i) => {
              const img = extractThumb
                ? extractThumb(image)
                : { fixed: image.fixed }
              return (
                <div key={i}>
                  <Box
                    pr={1}
                    flex={'0 0 auto'}
                    width={'auto'}
                    onClick={() => mainSlider.slickGoTo(i)}
                  >
                    <PreviewCompatibleImage
                      // styles={{ borderRadius: 3 }}
                      btrr={1}
                      bbrr={1}
                      btlr={i !== 0 && 1}
                      bblr={i !== 0 && 1}
                      // styles={{
                      //   width: '100%',
                      // }}
                      imgStyle={{ width: '100%' }}
                      imageInfo={img}
                    />
                  </Box>
                </div>
              )
            })}
            {/* </Flex> */}
          </Slider>
        </Box>
      )}
    </SlickWrapper>
  )
}
export default ImageSlider
