import PropTypes from 'prop-types'
import styled from 'styled-components'
import { system } from 'styled-system'

import { Box } from '../'
const config = {
  cols: {
    property: 'gridTemplateColumns',
    transform: n => `repeat(${n}, 1fr)`,
    // scale: [1, 2, 3, 4],
  },
  rows: {
    property: 'gridTemplateRows',
    transform: n => (n === 'auto' ? 'auto 1fr' : `repeat(${n}, 1fr)`),
    // scale: [1, 2, 3, 4],
  },
  gap: {
    property: 'gridGap',
    scale: 'space',
  },
}
const gridProps = system(config)
const Grid = styled(Box)`
  display: grid;
  ${gridProps}
  /* ${props =>
    typeof props.repeat == 'number' &&
    `grid-template-columns: repeat(${props.repeat}, 1fr)`}
   ${props =>
     Array.isArray(props.repeat) &&
     props.theme.breakpoints.map(
       (breakPoint, i) =>
         props.repeat[i] &&
         `@media screen and (min-width: ${breakPoint}){
          grid-template-columns: repeat(${props.repeat[i]}, 1fr);
      }`
     )} */
     ${props => props.overflow && ` overflow-y: scroll;`} 
}
`
Grid.displayName = 'Grid'
Grid.propTypes = {
  repeat: PropTypes.number,
  rows: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  cols: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
}
Grid.defaultProps = {
  repeat: 1,
}
export default Grid
