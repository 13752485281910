import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Box,
  Grid,
  Flex,
  Container,
  Text,
  Heading,
  IconButton,
  Icon,
} from '../UI'
import Logo from '../Logo'
import ScrollableLink from '../ScrollableLink'
const NavItem = styled(Text).attrs({ fontWeight: 700 })`
  &:hover a {
    color: ${props => props.theme.colors.secondary[1]};
  }
`
const SocialLink = styled(Text).attrs({ mb: 0 })`
  a {
    color: ${props => props.theme.colors.secondary[2]};
  }
  &:hover a {
    color: ${props => props.theme.colors.secondary[1]};
  }
`

function Footer(props) {
  return (
    <StaticQuery query={query}>
      {({ FooterSocial, MainNav }) => (
        <Box as="footer" aria-label="Footer">
          <Box bg="primary.0" px={{ _: 2, md: 3 }} py={3} color="secondary.2">
            <Container>
              <Grid cols={{ _: 1, md: 3 }} gap={2}>
                <FooterCol>
                  <Flex height="auto" justifyContent="flex-end">
                    <Logo
                      color="contrastSecondary"
                      alignSelf="flex-start"
                      width={280}
                      ml={-105}
                    />
                  </Flex>
                </FooterCol>
                <FooterCol title={'Contact & Social'}>
                  {FooterSocial.menuItems.map((item, i) => (
                    <SocialLink fontSize={{ _: 0, lg: 2 }} key={i}>
                      <Box href={item.path} as="a" py={'xs'}>
                        {item.icon && (
                          <Icon name={item.icon} mr={{ _: 'xs', lg: 1 }} />
                        )}
                        {item.label}
                      </Box>
                    </SocialLink>
                  ))}
                </FooterCol>
                <FooterCol title={'Navigation'} role="footermenusite">
                  <Flex flexDirection="row">
                    <Box width={1 / 2}>
                      {MainNav.menuItems.map((menu, i) => (
                        <NavItem key={i}>
                          <ScrollableLink to={menu.path}>
                            {menu.label}
                          </ScrollableLink>
                        </NavItem>
                      ))}
                    </Box>
                  </Flex>
                </FooterCol>
              </Grid>
            </Container>
          </Box>
          <Box p={1} bg={'secondary.1'}>
            <Flex
              width={1}
              pt={2}
              flex={1}
              flexDirection="row"
              justifyContent={'space-between'}
            >
              <Text>&copy; {new Date().getFullYear()} Rizzolo Guitars.</Text>
              <Text>
                Designed & developed by{' '}
                <Text
                  as="a"
                  color="primary.0"
                  css={css({
                    'text-decoration': 'underline',
                    'text-decoration-style': 'dashed',
                    'text-decoration-color': 'tomato',
                  })}
                  href="https://renrizzolo.com"
                >
                  Ren Rizzolo
                </Text>
              </Text>
            </Flex>
          </Box>
        </Box>
      )}
    </StaticQuery>
  )
}

const FooterCol = ({ title, children }) => (
  <Box className="has-dark-background">
    {title && (
      <Heading fontSize={{ _: 3, md: 4 }} mb={2}>
        {title}
      </Heading>
    )}
    {children}
  </Box>
)

Footer.propTypes = {}

const query = graphql`
  query FooterMenus {
    MainNav: contentfulMenu(name: { eq: "Top Menu" }) {
      name
      menuItems {
        label
        path
      }
    }

    FooterSocial: contentfulMenu(name: { eq: "Footer Social" }) {
      name
      menuItems {
        label
        path
        icon
      }
    }
  }
`

export default Footer
