import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon, Box } from '../UI'
import { default as SlickSliderDefault } from 'react-slick'
// import './slick.css'

const SlickSlider = styled.div`
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    /* -ms-touch-action: pan-y;
        touch-action: pan-y; */
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-slide {
    cursor: grab;
  }
`

const SliderArrow = styled(Box)`
  &:focus {
    color: ${props => props.theme.colors.contrastPrimary};
    box-shadow: ${props => props.theme.shadows.small};
  }
  z-index: 1;
  position: absolute;
  top: 50%;
  display: block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: normal;
  /* width: 75px;
  height: 75px; */
  display: flex;
  align-items: center;
  justify-content: center;
  &.slick-prev, &.slick-next {
    background: rgba(255, 255, 255, 0.75);
  }
  &.slick-prev {
    left: 1rem;
    span {
      margin-left: 0px;
    }
  }
  &.slick-next {
    right: 1rem;
    span {
      margin-right: 0px;
    }
  }

  svg {
    transform: scale(1);
    transition: all 300ms ease-in;
  }
  transition: all 600ms ease;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  &.slick-disabled {
    display: none;
    box-shadow: none;
    /* color: ${props => props.disabledColor}; */
    opacity: 0.3;
  }
`

const PrevArrow = props => {
  const { className, style, onClick, prevArrowProps } = props
  return (
    <SliderArrow
      aria-label="Previous"
      onClick={onClick}
      boxShadow={'small'}
      borderRadius={4}
      className={className}
      {...prevArrowProps}
    >
      <Icon name="arrow-left" />
    </SliderArrow>
  )
}
const NextArrow = props => {
  const { className, style, onClick, nextArrowProps } = props
  return (
    <SliderArrow
      aria-label="Next"
      boxShadow={'small'}
      borderRadius={4}
      onClick={onClick}
      className={className}
      {...nextArrowProps}
    >
      <Icon name="arrow-right" />
    </SliderArrow>
  )
}
SliderArrow.defaultProps = {
  width: 45,
  minHeight: 45,
  as: 'button',
  p: 1,
  bg: 'white',
  color: 'primary.1',
  disabledColor: 'lightgrey',
}
const Slider = React.forwardRef(
  (
    { children, settings, nextArrowProps, prevArrowProps, className },
    ref,
    ...rest
  ) => {
    const sett = { ...defaultSettings, ...settings }
    return (
      <SlickSlider className={className}>
        <SlickSliderDefault
          ref={ref}
          {...sett}
          {...rest}
          nextArrow={<NextArrow nextArrowProps={nextArrowProps} />}
          prevArrow={<PrevArrow prevArrowProps={prevArrowProps} />}
        >
          {children}
        </SlickSliderDefault>
      </SlickSlider>
    )
  }
)
const defaultSettings = {
  cssEase: 'cubic-bezier(0.67, 0.13, 0.35, 0.88)',
  speed: 450,
}
Slider.propTypes = {
  nextArrowProps: PropTypes.object,
  prevArrowProps: PropTypes.object,
  ...SlickSlider.propTypes,
}
export default Slider
