import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { flexDirection, flex } from 'styled-system'
import PreviewCompatibleImg from '../PreviewCompatibleImg'
import { Text, Heading, Box, Flex } from '../UI'

const CardStyled = styled(Box)`
  ${flexDirection}
`
const TextBox = styled(Box)`
  ${flex}
`

const Card = ({
  children,
  image,
  heading,
  text,
  backgroundFade,
  backgroundImage,
  textProps,
  headingProps,
  imageProps,
  textBoxProps,
  ...rest
}) => {
  return (
    <CardStyled
      {...rest}
      display="flex"
      overflow="hidden"
      style={{
        backgroundImage: backgroundImage
          ? backgroundFade
            ? `${backgroundFade}, url(${backgroundImage})`
            : backgroundImage
          : undefined,
      }}
      // backgroundImage={
      //   backgroundImage
      //     ? backgroundFade
      //       ? `${backgroundFade}, url(${backgroundImage})`
      //       : backgroundImage
      //     : null
      // }
    >
      {image ? (
        <PreviewCompatibleImg {...imageProps} imageInfo={image} />
      ) : (
        <Box className="post-image-placeholder" {...imageProps}></Box>
      )}
      <TextBox {...textBoxProps}>
        {heading && <Heading {...headingProps}>{heading}</Heading>}
        {text && <Text {...textProps}>{text}</Text>}
        {children && children}
      </TextBox>
    </CardStyled>
  )
}

Card.propTypes = {
  image: PropTypes.object,
  heading: PropTypes.string,
  text: PropTypes.string,
}
Card.defaultProps = {
  backgroundFade: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent)',
  boxShadow: 'small',
  borderRadius: 2,
  width: 1,
  textProps: { fontSize: 1 },
  headingProps: {},
  imageProps: {},
  // mx: 1,
  p: 0,
  // variant: 'basic',
  textBoxProps: {
    p: 2,
    width: 1,
  },
  flexDirection: 'column',
}

export default Card
