import React, { createRef, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Icon, Flex, Box } from '../UI'

const SearchIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  left: 0;
  margin: 0;
  z-index: 2;
`

const SearchInput = styled.input`
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.space[2]}px ${theme.space[2]}px ${theme.space[2]}px ${theme.space[3]}px`};
  background-color: ${props => props.theme.colors.primary[1]};
  outline: none;
  border: none;
  font-size: 1.5em;
  transition: all 300ms ease;
  z-index: 1;
  width: 100%;
  &.collapse {
    /* width: 0; */
    z-index: -1;
    cursor: pointer;
    color: ${props => props.theme.colors.secondary[1]};
    + ${SearchIcon} {
      color: white;
    }
  }
  &.expand {
    color: ${props => props.theme.colors.secondary[1]};
    ::placeholder {
      color: ${props => props.theme.colors.secondary[2]};
    }
  }
`
const Form = styled(Flex)`
  height: 75px;
`

const SearchBox = props => {
  const { refine, expand, focus, query, ...rest } = props
  const delay = 2000
  const inputEl = useRef(null)

  const setFocus = () => inputEl.current && inputEl.current.focus()

  useEffect(() => {
    if (expand) {
      setFocus()
    }
  }, [expand])

  const onChangeDebounced = event => {
    const val = event.currentTarget.value
    refine(val)
    // clearTimeout(timerId)
    // let timerId = setTimeout(() => refine(val), delay)
  }

  return (
    <Form as="form" align="center" flexDirection="row-reverse" role="search">
      <SearchInput
        tabIndex={expand ? 0 : -1}
        bg="primary.1"
        ref={inputEl}
        height={'100%'}
        as="input"
        pl={3}
        pr={2}
        py={2}
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={onChangeDebounced}
        className={expand ? 'expand' : 'collapse'}
        {...rest}
      />
      <SearchIcon p={1} name={['fa', 'search']} />
    </Form>
  )
}

const Input = connectSearchBox(SearchBox)

export default Input
