import React from 'react'
import css from '@styled-system/css'
import { Icon } from '../UI'

export const Loader = ({ show }) => {
  return show ? (
    <div css={css({ textAlign: 'center' })}>
      <Icon
        css={css({
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        })}
        name="spinner"
        fontSize={4}
        color={'lightgrey'}
        pulse
      />
    </div>
  ) : null
}
