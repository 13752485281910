import styled from 'styled-components'
import { Text } from '../'
const Heading = styled(Text)``

Heading.defaultProps = {
  fontFamily: 'serif',
  as: 'h2',
  mt: 0,
}
Heading.displayName = 'Heading'
export default Heading
