import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '../Box'
import Text from '../Text'

const LabelContainer = styled(Box)`
  vertical-align: middle;
`
const LabelText = styled(Text)``

LabelContainer.defaultProps = {
  as: 'span',
  display: 'inline-block',
  bg: 'secondary.2',
  mb: 2,
  py: 'xs',
  px: 1,
  borderRadius: 1,
}
LabelText.defaultProps = {
  display: 'inline',
  fontWeight: 500,
  letterSpacing: 0.25,
  color: 'primary.2',
  m: 0,
  fontFamily: 'sans',
  as: 'p',
  fontSize: 'xs',
  lineHeight: 1,
}

const Label = ({ children, boxProps, textProps, ...rest }) => (
  <LabelContainer {...boxProps} {...rest}>
    <LabelText {...textProps}>{children}</LabelText>
  </LabelContainer>
)
Label.displayName = 'Label'

Label.propTypes = {
  boxProps: PropTypes.object,
  textProps: PropTypes.object,
}

export default Label
