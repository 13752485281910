import React from 'react'
import { Container, Box, FullWidth } from '../../UI'

const OffsetBox = ({ left, right, center, bg, br, children, ...rest }) => {
  return (
    <Container>
      <FullWidth left={left} right={right} center={center}>
        <Box
          ml={{ _: -2, md: center ? 2 : right ? 2 : -2 }}
          mr={{ _: -2, md: center ? 2 : right ? -2 : 2 }}
          width={'auto'}
          py={{ _: 2, md: 3 }}
          pr={{ _: 2, md: right ? 4 : 3 }}
          pl={{ _: 2, md: left ? 4 : 3 }}
          mb={2}
          borderRadius={br}
          bg={bg}
          {...rest}
        >
          {children}
        </Box>
      </FullWidth>
    </Container>
  )
}
OffsetBox.defaultProps = {
  bg: 'primary.1',
  br: { _: null, md: 2 },
}

export default OffsetBox
