import React from 'react'
import PropTypes from 'prop-types'
import { Flex, IconButton } from '../UI'
import { Location } from '@reach/router'
import { lighten } from 'polished'
import { useStaticQuery } from 'gatsby'

const SharingButtons = ({ title, path, size, theme }) => {
  const data = useStaticQuery(graphql`
    query SharingMeta {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const url = data.site.siteMetadata.siteUrl
  return (
    <Location>
      {({ location }) => {
        const { href } = location
        const urlToShare = path ? url + path : href
        const message = `Check out ${title} on Rizzolo Guitars`
        const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`
        const twitterUrl = `https://twitter.com/intent/tweet/?text=${message}&url=${urlToShare}`
        const email = `mailto:?subject=&body=${message} - ${urlToShare}`
        const urls = {
          facebook: fbUrl,
          twitter: twitterUrl,
          email: email,
        }
        const colors = {
          twitter: '#1da1f2',
          facebook: '#3b5998',
          email: '#222',
        }
        const icons = {
          twitter: ['fab', 'twitter'],
          facebook: ['fab', 'facebook-f'],
          email: ['fa', 'envelope'],
        }
        const themes = provider => ({
          outlined: {
            background: 'white',
            color: colors[provider],
            borderColor: colors[provider],
            borderHoverColor: lighten(0.2, colors[provider]),
            hoverColor: lighten(0.2, colors[provider]),
          },
          dark: {
            background: 'primary.1',
            color: 'white',
            bgHoverColor: 'primary.0',
            hoverColor: 'secondary.1',
          },
        })
        const sizes = {
          small: {
            fontSize: 0,
            py: 10,
            px: 10,
            width: 34.25,
            height: 34.25,
            mr: 7.5,
            // background: 'none'
          },
        }
        const providers = ['facebook', 'twitter', 'email']

        return (
          <Flex align="center" flexDirection="row">
            {providers.map(provider => (
              <IconButton
                key={provider}
                as="a"
                round
                target="_blank"
                mr={1}
                py={11.5}
                px={8}
                aria-label={`share via ${provider}`}
                // width={40}
                // height={40}
                color={colors.white}
                href={urls[provider]}
                background={colors[provider]}
                hoverColor={colors[provider]}
                bgHoverColor="#fff"
                transform={'shrink-1'}
                name={icons[provider]}
                {...themes(provider)[theme]}
                {...sizes[size]}
              />
            ))}
          </Flex>
        )
      }}
    </Location>
  )
}
SharingButtons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
}

export default SharingButtons
