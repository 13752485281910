import React, { Children } from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import styled from 'styled-components'
import get from 'lodash.get'
import { Box, Text, Heading } from '../UI'
import Slider from '../Slider'
import Portal from '../../helpers/Portal'
import Instrument from '../Instrument/Instrument'
import ScrollableLink from '../ScrollableLink'
import ImageSlider from '../ImageSlider'

const RichContent = styled(Text).attrs({ as: 'div' })`
  * {
    line-height: 1.45;
  }
  p {
    white-space: pre-wrap;
    margin-top: 0;
    line-height: 1.5;
    /* @media screen and (max-width: 768px) {
      font-size: 1.08rem;
    } */
  }
  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.lightgrey};
    color: ${props => props.theme.colors.lightgrey};
    border-bottom: none;
  }
  blockquote {
    margin-left: 0px;
    padding-left: ${props => props.theme.space[2]}px;
    border-left: 4px solid ${props => props.theme.colors.lightergrey};
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul {
    list-style: disc;
    padding-left: 18px;
  }
`

const IframeContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

RichContent.defaultProps = {
  as: 'p',
}

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading mb={1} mt={3} as="h1">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading mb={1} mt={3} as="h2">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading fontFamily="sans" mb={1} as="h3">
        {children}
      </Heading>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.includes('player.vimeo.com/video')) {
        return (
          <IframeContainer>
            <iframe
              title={node.data.uri}
              src={node.data.uri}
              frameBorder="0"
              allowFullScreen
            />
          </IframeContainer>
        )
      } else if (node.data.uri.includes('youtube.com/embed')) {
        return (
          <IframeContainer>
            <iframe
              title={node.data.uri}
              src={node.data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            />
          </IframeContainer>
        )
      } else if (
        !node.data.uri.startsWith('http') &&
        node.data.uri.startsWith('/')
      ) {
        return <ScrollableLink to={node.data.uri}>{children}</ScrollableLink>
      } else {
        return <a href={node.data.uri}>{children}</a>
      }
    },

    'embedded-asset-block': (node, next) => {
      // we don't have access to content
      return (
        <PreviewCompatibleImage
          mb={2}
          style={{ maxWidth: '100%' }}
          imageInfo={{
            image: `${node.data.target.fields.file['en-US'].url}?q=50`,
          }}
        />
      )
    },

    'embedded-entry-block': node => {
      const contentType =
        get(node, 'data.target.sys.contentType.sys.id') || null
      if (contentType === 'slider') {
        const slider = doStuff(node.data.target)

        return (
          <ImageSlider
            thumbs={true}
            images={slider.images}
            settings={{ infinite: false }}
            extractImg={img => {
              return { image: `${img.file.url}?q=60` }
            }}
            extractThumb={img => {
              return { image: `${img.file.url}?q=40&w=100&h=100&fit=thumb` }
            }}
          />
        )
      } else if (contentType === 'instrument') {
        // do all the stuffs
        const instrument = doStuff(node.data.target)

        return <Instrument showPreview={false} instrument={instrument} />
      } else if (contentType === 'imageWithCaption') {
        const img = doStuff(node.data.target)
        return (
          <Box
            mb={2}
            // display="inline-block"
            css={
              img.wide &&
              css({
                marginLeft: 'calc(10% - 10vw)',
                marginRight: 'calc(10% - 10vw)',
              })
            }
            // borderBottom="1px solid"
            // borderColor="lightergrey"
          >
            <PreviewCompatibleImage
              b={1}
              styles={{ width: img.wide ? '100%' : 'auto' }}
              imageInfo={{ image: `${img.image.file.url}?q=50` }}
            />
            {img.caption && (
              <Text m={0} color={'grey'} fontSize={0}>
                {img.caption}
              </Text>
            )}
          </Box>
        )
      }
    },
  },
}

// normalizes the embedded entry object, removing field and locale
// so it's the same as our regular top level graphql queries
// adapted from https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/src/normalize.js
/**
 * normalizes the embedded entry object, removing field and locale.
 * @param {object} entryItem
 * @return {object} stuff.
 */

const doStuff = entryItem => {
  let stuff = {}
  const defaultLocale = 'en-US'
  const entryItemFields = entryItem.fields

  if (entryItemFields) {
    Object.keys(entryItemFields).forEach(entryItemFieldKey => {
      if (entryItemFields[entryItemFieldKey]) {
        let entryItemFieldValue =
          entryItemFields[entryItemFieldKey][defaultLocale]
        // If this is an array of single reference object
        // add to the reference map, otherwise ignore.
        stuff = { ...stuff, [entryItemFieldKey]: entryItemFieldValue }

        if (Array.isArray(entryItemFieldValue)) {
          if (
            entryItemFieldValue[0] &&
            entryItemFieldValue[0].sys &&
            entryItemFieldValue[0].sys.type &&
            entryItemFieldValue[0].sys.id
          ) {
            const arr = []
            entryItemFieldValue.forEach(v => {
              arr.push(doStuff(v))
            })
            stuff = {
              ...stuff,
              [entryItemFieldKey]: arr,
            }
          }
        } else if (
          entryItemFieldValue &&
          entryItemFieldValue.sys &&
          entryItemFieldValue.sys.type &&
          entryItemFieldValue.sys.id
        ) {
          stuff = {
            ...stuff,
            [entryItemFieldKey]: doStuff(entryItemFieldValue),
          }
        }
      }
    })
    return stuff
  }
}

const Content = ({ document, content, className, ...rest }) => {
  const html = documentToReactComponents(document, options)
  return document ? (
    <RichContent fontFamily={'sans'} {...rest} as={'div'} className={className}>
      {html}
    </RichContent>
  ) : (
    <RichContent
      {...rest}
      as={'div'}
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

export default Content
