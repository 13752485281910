import React, { createRef, useEffect } from 'react'
import css from '@styled-system/css'
import useClickOutside from '../../helpers/useClickOutside'
import { useSpring, animated } from 'react-spring'
import { Container, Icon, Text, IconButton } from '../UI'
import FullInstrument from './FullInstrument'
import useKeyPress from '../../helpers/useKeyPress'

const PreviewInstrument = ({
  id,
  toggleInstrument,
  expanded,
  imageExpanded,
  expandPreview,
  ...rest
}) => {
  const backdropRef = createRef()
  const esc = useKeyPress('Escape')
  useClickOutside(backdropRef, toggleInstrument, imageExpanded)
  // modal doesn't get or trap focus...
  useEffect(() => {
    // not really sure why I can't
    // immediately set the focus...
    setTimeout(() => {
      document.getElementById(`inst-expand-bg-portal-${id}`).focus()
    }, 500)
  }, [])
  useEffect(() => {
    // not sure how to keep this esc
    // listener after fullscreen slider
    // is closed with esc
    esc && !imageExpanded && toggleInstrument()
  }, [esc])

  return (
    <Container
      role={'dialog'}
      id="preview-modal"
      aria-modal="true"
      ref={backdropRef}
      css={css({
        'max-height': 'calc(100vh - 8em)',
        position: 'relative',
        maxWidth: '45vh', // this works surprislingly well
        transform: 'translateY(-50%)',
        top: '50%',
      })}
      borderRadius={2}
      p={{ _: 2, md: 0 }}
      // background="white"
    >
      <FullInstrument
        {...rest}
        imageExpanded={imageExpanded}
        toggleInstrument={toggleInstrument}
      />
    </Container>
  )
}
export default PreviewInstrument
