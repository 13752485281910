import styled from 'styled-components'
import {  flexbox, space, display, height, variant } from 'styled-system'


const Flex = styled('div')`
  ${height}
  ${space}
  ${flexbox}
  ${display}
  ${variant({
    prop: 'align',
    variants: {
      center: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
  })}
`
Flex.defaultProps = {
  display: 'flex',
  flexDirection: 'column'
}
Flex.displayName = 'Flex'
export default Flex
