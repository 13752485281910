import React from 'react'
import styled from 'styled-components'
import {
  space,
  color,
  typography,
  textShadow,
  compose
} from 'styled-system'
import css from '@styled-system/css'

const textProps = compose(
  space,
  typography,
  color,
  textShadow
)
const Text = styled('p')(textProps)

Text.defaultProps = {
  fontFamily: 'sans',
  as: 'p',
  mt: 0,
  lineHeight: 3,
  // fontSize: 1,
}
Text.displayName = 'Text'
export default Text
