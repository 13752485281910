import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'

export default class ScrollableLink extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['link', 'navLink']),
    element: PropTypes.string,
    // maxWidth: PropTypes.number,
  }
  static defaultProps = {
    type: 'link',
    element: 'site-top',
    activeClassName: 'active',
    // maxWidth: 540
  }

  scrollTo = () => {
    const { element } = this.props
    const el = document.getElementById(element)

    // if (window.innerWidth < maxWidth) {
    // setTimeout(() => {

    //   el && el.scrollIntoView();
    // }, 1000);
    // }
    window.scrollTo(0, 0)
  }

  render() {
    const {
      element,
      type,
      state,
      back,
      exitProps,
      entryProps,
      fallback,
      to,
      scrollTo,
      ...rest
    } = this.props

    return (
      <TransitionLink
        // preventScrollJump
        // onClick={this.scrollTo}
        exit={{
          length: 0.9,
          // delay: 0.9,
          state: { nextPath: to },
          ...exitProps,
        }}
        entry={{
          delay: 0.9,
          length: 0.9,
          state: { nextPath: to },
          ...entryProps,
        }}
        to={
          // back ?
          // location.state &&
          // (location.state.prevPath
          //   ? location.state.prevPath
          //   : fallback)
          //   :
          to
        }
        {...rest}
        state={{
          ...state,
          // ...location.state,
          // prevPath: location.pathname,
        }}
      />
    )
  }
}
