import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'

import { animated } from 'react-spring'
import FullScreenSlider from '../FullScreenSlider'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import Slider from '../Slider'
import { Flex, Box, Text, Heading, IconButton, Label, Grid } from '../UI'
import SharingButtons from '../SharingButtons'
import ScrollableLink from '../ScrollableLink'
import Content from '../Content'
import numberWithCommas from '../../helpers/numberWithComma'
import getPriceString from '../../helpers/getPriceString'

const SlickWrapper = styled(Box)`
  z-index: 1;
  position: relative;
  overflow: hidden;
  &.rounded .slick-list,
  &.rounded .slick-slider {
/*     
    @media screen and (min-width: 700px) {
      border-radius: ${props => props.theme.radii[2]};
    }
    border-top-right-radius: ${props =>
      props.preview ? 0 : props.theme.radii[2]};
    border-bottom-right-radius: ${props => props.theme.radii[2]};
     */
  }
  .slick-slider {
    background-color: ${props => props.theme.colors.lightergrey};
  }
  .slick-track {
    align-items: center;
    /* justify-content: center; */
    display: flex;
    @media screen and (min-width: 700px) {
      .gatsby-image-wrapper {
        max-height: ${props =>
          props.preview
            ? '80vh'
            : `calc(100vh - ${props.theme.space.navBarHeight}px - ${props.theme.space[6]}px)`};
      }
    }

    .gatsby-image-wrapper {
      max-height: ${props =>
        props.preview
          ? '80vh'
          : `calc(75vh - ${props.theme.space.navBarHeight}px)`};
      img {
        object-fit: contain !important;
      }
    }
    background-color: ${props => props.theme.colors.lightergrey};
  }
  &.full-screen {
    .slick-list {
      min-height: 100vh;
    }
    .slick-track {
      min-height: 100vh;
    }
  }
  /* new thumbs test using custom dots */
  .thumbs-pager {
    margin-top: ${props => props.theme.space[2]}px;
    margin-bottom: ${props => props.theme.space[2]}px;
    overflow-x: scroll;
    margin-left: -6px;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-gap: 6.5px;
  }
`
const ThumbsWrapper = styled(Box)`
  .slick-slider {
    /* margin-right: -10px;
    margin-left: -10px; */
  }
  .slick-prev {
    left: 0rem;
    @media screen and (min-width: 700px) {
      left: -1rem;
    }
  }
  .slick-next {
    right: 0rem;
    @media screen and (min-width: 700px) {
      right: -1rem;
    }
  }
  .slick-slider::focus {
    outline: none;
  }
  .slick-track {
    margin-left: 0;
  }
  .slick-slide.slick-active > div > div::focus {
    outline: none;
  }
`

const SectionHeading = styled(Heading).attrs({ fontSize: 3, mb: 1 })`
  /* border-bottom: 2px solid; */
  color: ${({ theme }) => theme.colors.contrastSecondary};
`

const LargePrice = ({ children }) => (
  <Text fontWeight={900} color="primary.1" mb={0} fontSize={3}>
    {children}
  </Text>
)

const Container = styled(Flex)`
  position: relative;
`

const InfoLineContainer = styled(Grid)`
  /* div:nth-of-type(odd) {
    background-color: ${props => props.theme.colors.secondary[2]};
  }
  div:nth-of-type(even) {
    background-color: ${props => props.theme.colors.white};
  } */
`
const InfoLine = ({ property, val }) => (
  <Flex width={1} py={8} px={1}>
    {property && (
      <Text style={{ flexBasis: '30%' }} mb={0} mr={2} fontWeight="bold">
        {property}
      </Text>
    )}
    {val && (
      <Text flex={1} mb={0}>
        {val}
      </Text>
    )}
  </Flex>
)

const InfoLineStacked = ({ property, value, optional }) => (
  <Flex
    // mx={1}
    width={1}
    // py={'xs'}
    // px={0}
    flexWrap={'wrap'}
    style={
      {
        // display: 'inline-grid',
        // gridTemplateColumns: 'minmax(60px, max-content) 1fr',
      }
    }
  >
    {property && (
      <Box width={1} fontSize={0}>
        <Text flex={1} mb={0} fontWeight="bold">
          {property}{' '}
          {optional && (
            <Text as="span" fontWeight="normal">
              (optional)
            </Text>
          )}
        </Text>
      </Box>
    )}
    {value && (
      <Box width={1} fontSize={0}>
        <Text flex={1} mb={0} fontSize={[0, 0, 1]}>
          {value}
        </Text>
      </Box>
    )}
  </Flex>
)

class FullInstrument extends React.Component {
  state = {
    mainSlider: null,
    thumbsSlider: null,
  }

  componentDidMount() {
    this.setState({
      currentImageIndex: 0,
      mainSlider: this.mainSlider,
      thumbsSlider: this.thumbsSlider,
    })
    this.mainSlider &&
      this.mainSlider.innerSlider.list.setAttribute('tabindex', 0)
    this.mainSlider && this.mainSlider.innerSlider.list.focus()
  }
  focusSlider = () => {
    this.mainSlider &&
      this.mainSlider.innerSlider.list.setAttribute('tabindex', 0)
    this.mainSlider && this.mainSlider.innerSlider.list.focus()
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.imageExpanded === false &&
      prevProps.imageExpanded === true
    ) {
      this.focusSlider()
    }
  }

  handleBeforeChange = (prev, current) => {
    this.setState({
      currentImageIndex: current,
    })
    this.focusSlider()
    // this.mainSlider.slickGoTo(current)
  }

  render() {
    const {
      instrument: {
        title,
        subTitle,
        price,
        type,
        slug,
        tags,
        stock,
        slider,
        description,
        meta,
        heroImage,
      },
      toggleInstrument,
      imageExpanded,
      expandImage,
      imageProps,
      imagePropsInverse,
      fullPage,
      preview,
    } = this.props

    const instPrice = getPriceString(price)

    const { currentImageIndex } = this.state
    const hasSliderImages =
      slider && slider.images && Array.isArray(slider.images)

    return (
      <Container mt={preview ? 0 : 50} flexDirection={'row'} flexWrap={'wrap'}>
        {imageExpanded && !preview && (
          <FullScreenSlider
            animatedStyle={{
              transform: imagePropsInverse.xy.to(
                (x, y) => `translate3d(${x}px, ${y}px, 0)`
              ),
              opacity: imagePropsInverse.opacity,
            }}
            onClose={expandImage}
            images={hasSliderImages && slider.images}
            currentImageIndex={currentImageIndex}
          />
        )}
        <Flex
          flexDirection="column"
          flex={{ _: '100%', xs: '100%', md: preview ? 1 : 0.8 }}
          style={{ minHeight: 0, minWidth: 0 }}
          // mr={preview ? 2 : 0}
          // mb={preview ? 1 : 0}
        >
          <Box
            btrr={2}
            btlr={2}
            mt={preview ? -2 : -3}
            ml={preview ? 0 : { _: -2, xs: -2, sm: -3, md: -2 }}
            mb={2}
            style={{ zIndex: 1 }}
          >
            <animated.div
              style={{
                transform: imageProps.xy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`
                ),
                opacity: imageProps.opacity,
              }}
            >
              {/* PREVIEW BUTTONS */}
              {preview && (
                <Box
                  css={css({
                    position: 'absolute',
                    top: 1,
                    left: 1,
                    right: 1,
                    zIndex: 3,
                  })}
                >
                  {/* <Heading as="h3">{title}</Heading> */}

                  <Flex
                    justifyContent="space-between"
                    flexDirection="row"
                    py={'xs'}
                  >
                    <IconButton
                      onClick={toggleInstrument}
                      to={`/instrument/${slug}`}
                      small
                      variant="primary"
                      as={ScrollableLink}
                      name="arrow-right"
                      // exitProps={{ delay: 0.5 }}
                    >
                      View Instrument
                    </IconButton>
                    <IconButton
                      ml={2}
                      id="close-modal"
                      border="none"
                      variant="secondary"
                      round
                      aria-label="Close preview"
                      name={'times'}
                      onClick={toggleInstrument}
                    />
                  </Flex>
                </Box>
              )}
              <SlickWrapper
                preview={preview}
                className="rounded"
                btrr={{ _: 2, md: 2 }}
                bbrr={{ _: 2, md: 2 }}
                btlr={{ _: 0, md: 2 }}
                bblr={{ _: 0, md: 2 }}
              >
                {fullPage && hasSliderImages && !preview && (
                  <IconButton
                    round
                    onClick={expandImage}
                    bg="lightergrey"
                    variant="none"
                    color="grey"
                    aria-label="Expand gallery"
                    name="expand"
                    style={{
                      zIndex: 1,
                      position: 'absolute',
                      top: 12.5,
                      right: 12.5,
                    }}
                  />
                )}

                {!hasSliderImages && (
                  <PreviewCompatibleImage
                    placeHolderStyle={{ backgroundColor: '#dadada' }}
                    // minHeight={700}
                    styles={{
                      display: 'block',
                      overflow: 'hidden',
                      backgroundColor: '#dadada',
                      width: heroImage.file && '100%',
                    }}
                    btrr={2}
                    btlr={{ _: 0, sm: 2 }}
                    bblr={{ _: 0, sm: 2 }}
                    bbrr={2}
                    minHeight="100%"
                    imageInfo={
                      heroImage.file
                        ? { image: `${heroImage.file.url}?q=50&w=400` }
                        : { fluid: heroImage.fluid }
                    }
                  />
                )}
                {hasSliderImages && (
                  <Slider
                    ref={slider => (this.mainSlider = slider)}
                    settings={{
                      accessibility: true,
                      adaptiveHeight: false,
                      beforeChange: this.handleBeforeChange,
                      centerPadding: '0',

                      // can be used instead of creating
                      // thumbs component below
                      // but we don't want it inside the
                      // rounded border container in this case
                      // dots: true,
                      // dotsClass: 'thumbs-pager',
                      // customPaging: function(i) {
                      //   return (
                      //     <a>
                      //       <PreviewCompatibleImage
                      //         btrr={1}
                      //         bbrr={1}
                      //         btlr={i !== 0 && 1}
                      //         bblr={i !== 0 && 1}
                      //         styles={
                      //           {
                      //             // width: 100,
                      //           }
                      //         }
                      //         imgStyle={{
                      //           width: '100%',
                      //         }}
                      //         imageInfo={{
                      //           fixed: slider.images[i].fixed,
                      //         }}
                      //       />
                      //     </a>
                      //   )
                      // },
                      // initialSlide: this.state.currentImageIndex,
                      // asNavFor: this.thumbsSlider,
                      infinite: false,
                      arrows: false,
                    }}
                  >
                    {slider.images.map((image, i) => (
                      <PreviewCompatibleImage
                        key={i}
                        showLoading
                        placeHolderStyle={{ backgroundColor: '#dadada' }}
                        styles={{
                          display: 'block',
                          overflow: 'hidden',
                          backgroundColor: '#dadada',
                        }}
                        imageInfo={
                          image.file
                            ? { image: `${image.file.url}?q=50&w=400` }
                            : { fluid: image.fluid }
                        }
                      />
                    ))}
                  </Slider>
                )}
              </SlickWrapper>
            </animated.div>
          </Box>
          {hasSliderImages && (
            <ThumbsWrapper
              flexDirection="row"
              mt={1}
              // width={1}
              ml={{ _: -37.5, md: 0 }} // ugh this is like 3 x spacing unit
              // style={{ overflowX: 'scroll' }} // touchAction: 'pan-x'
              pb={1}
              mr={-1}
            >
              <Slider
                settings={{
                  speed: 200,
                  slidesToScroll: 4,
                  slidesToShow: 4,
                  arrows: slider.images.length > 3,
                  infinite: false,
                  // focusOnSelect: true,
                  swipe: false,
                  // variableWidth: true,
                  responsive: [
                    {
                      breakpoint: 400,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 700,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1099,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                      },
                    },
                  ],
                }}
              >
                {slider.images.map((image, i) => (
                  <Box
                    key={i}
                    width={1}
                    // pl={i !== 0 && 'xs'}
                    // pl={i !== 0 && 1}
                    // pl={1}
                    pr={1} // idk why the last one is getting cut off without this
                    onClick={e => {
                      if (this.state.isDragging) {
                        e.preventDefault()
                      } else {
                        this.mainSlider.slickGoTo(i)
                      }
                    }}
                  >
                    <PreviewCompatibleImage
                      btrr={1}
                      bbrr={1}
                      btlr={(i !== 0 || preview) && 1}
                      bblr={(i !== 0 || preview) && 1}
                      styles={{
                        width: '100%',
                      }}
                      imgStyle={{ width: '100%' }}
                      // minWidth={{ _: 70, sm: 100, lg: 100 }}
                      // imgStyle={{ width: '100%' }}
                      imageInfo={
                        image.file
                          ? {
                              image: `${image.file.url}?q=50&w=100&h=100&fit=fill`,
                            }
                          : { fixed: image.fixed }
                      }
                    />
                  </Box>
                ))}
              </Slider>
            </ThumbsWrapper>
          )}
        </Flex>
        {!preview && (
          <Flex
            flex={1}
            flexDirection="column"
            // css={
            //   preview &&
            //   css({
            //     overflowY: 'auto',
            //     maxHeight: 'calc(100vh - 8em)',
            //   })
            // }
          >
            <Box
              mt={{ _: 1, md: 0 }}
              mx={[-2, -2, -2, 0]}
              px={[2, 2, 2, 2, 3]}
              py={{ _: 1, md: 2 }}
              // mx={{ _: -2, sm: -3, md: 0 }}
              //  px={{ _: 2, sm: 3, md: 2, lg: 3 }}
              bg="secondary.3"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                flexDirection="row"
              >
                <Box itemProp="price">
                  <SectionHeading>Price</SectionHeading>
                  <LargePrice>{instPrice}</LargePrice>
                </Box>
                <IconButton
                  to={`/contact`}
                  state={{
                    instrumentEnquiry: true,
                    instrumentLink: `/instrument/${slug}`,
                    instrumentName: title,
                  }}
                  as={ScrollableLink}
                  fontSize={1}
                  px={2}
                  py={1}
                  name="envelope"
                >
                  Make an enquiry
                </IconButton>
              </Flex>
            </Box>

            <Box mt={{ _: 2, md: 0 }} p={{ _: 0, md: 2, lg: 3 }}>
              {meta && meta.Metadata && meta.Metadata[0] && (
                <React.Fragment>
                  <SectionHeading mb={2}>
                    Materials & Specifications
                  </SectionHeading>
                  <meta itemProp="material" content={meta.Metadata[0].value} />
                </React.Fragment>
              )}
              <Flex flexDirection="row" flex={1} flexWrap="nowrap">
                <InfoLineContainer
                  cols={2}
                  gap={1}
                  // flexWrap="wrap"
                  // flex="0.5"
                  // maxHeight="500px"
                  // width={1}
                  // flexDirection="column"
                >
                  {meta &&
                    meta.Metadata &&
                    meta.Metadata.map(({ property, value, optional }) => (
                      <InfoLineStacked
                        key={property}
                        property={property}
                        value={value}
                        optional={optional}
                      />
                    ))}
                </InfoLineContainer>
              </Flex>
              {description && (
                <Box mt={2}>
                  <SectionHeading mb={2}>About</SectionHeading>

                  <Content document={description.json} />
                </Box>
              )}
              {tags && (
                <Box pt={2}>
                  {tags.map(tag => (
                    <Label key={tag} mr={1} mb={1}>
                      {tag}
                    </Label>
                  ))}
                </Box>
              )}
            </Box>

            <Flex mt={'auto'} p={2} alignSelf="flex-end">
              <Text>Share this</Text>
              <SharingButtons
                title={title}
                size={'small'}
                theme="dark"
                path={`/instrument/${slug}`}
              />
            </Flex>
          </Flex>
        )}
      </Container>
    )
  }
}

export default FullInstrument
