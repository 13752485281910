import styled from 'styled-components'
import Box from '../Box'

const Container = styled(Box)`
  max-width: ${props => `${props.theme.container}em`};
`

Container.defaultProps = {
  mx: 'auto',
  px: 2,
  width: 1,
}

export default Container
