import { useState, useEffect } from 'react'

const useKeyPress = targetKey => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  function downHandler(e) {
    const modifiers = {
      Control: e.controlKey,
      Shift: e.shiftKey,
      Alt: e.altKey,
      Meta: e.metaKey,
    }
    const key = e.key

    if (Array.isArray(targetKey)) {
      // this is to detect shift+tab navigation
      // on the sub-menu
      if (key === targetKey[1] && modifiers[targetKey[0]] === true) {
        setKeyPressed(true)
      } else {
        setKeyPressed(false)
      }
    } else if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}

export default useKeyPress
