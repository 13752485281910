import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { animated, useSpring } from 'react-spring'
import Portal from '../../helpers/Portal'
import { Box, Text, Button, IconButton } from '../UI'
import ScrollableLink from '../ScrollableLink'
import styled, { css } from 'styled-components'

const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  margin-right: ${props => props.theme.space[1]}px;
  border-radius: 4px;
  align-self: flex-start;
  @media screen and (max-width: 600px) {
    max-width: 70px;
  }
  @media screen and (max-width: 400px) {
    max-width: 100%;
    margin-bottom: ${props => props.theme.space[1]}px;
  }
`
const initial = { x: -600, opacity: 0 }
const Popup = React.memo(
  ({
    popupId,
    children,
    bg,
    to,
    heading,
    text,
    imgSrc,
    buttonLabel,
    onClose,
    hiddenRoutes = [],
  }) => {
    const [props, setProps] = useSpring(() => initial)

    const popupIsClosed = () => {
      if (typeof window === 'undefined') return
      return localStorage.getItem(popupId)
    }
    const closePopup = () => {
      if (typeof window === 'undefined') return

      localStorage.setItem(popupId, 'true')
      setProps(initial)
      onClose && onClose()
    }

    useEffect(() => {
      !popupIsClosed() &&
        setTimeout(() => {
          setProps({ x: 0, opacity: 1 })
        }, 4000)
      // return () => {
      //   cleanup
      // }
    }, [])
    const { pathname } = useLocation()
    console.log(pathname)

    if (hiddenRoutes.includes(pathname)) return null

    return (
      <animated.div
        style={{
          ...props,
          position: 'fixed',
          // top: 0,
          // right: 0,
          left: 0,
          bottom: 0,
          // backgroundColor: '#fafafa',
          zIndex: 100,
          display: popupIsClosed() ? 'none' : 'block',
        }}
      >
        <Box
          css={css({ flexWrap: 'wrap' })}
          display="flex"
          p={2}
          m={2}
          borderRadius={1}
          bg={bg || 'secondary.2'}
          boxShadow="medium"
          maxWidth={512}
        >
          <Img src={imgSrc} alt={heading} />
          <Box alignSelf="center" flex={1} mr={1}>
            <Text fontSize={2} mb={1} fontWeight="bold">
              {heading}
            </Text>
            <Text fontSize={0}>{text}</Text>

            <Button
              variant="outline"
              display="inline-block"
              px={2}
              py={1}
              as={ScrollableLink}
              onClick={closePopup}
              to={to}
            >
              {buttonLabel}
            </Button>
          </Box>
          <IconButton
            bg="secondary.1"
            color={'primary.0'}
            round
            name="times"
            aria-label="Close"
            onClick={closePopup}
          />
        </Box>
      </animated.div>
    )
  }
)

export default Popup
