import MenuItem from './MenuItem'
import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import ScrollableLink from '../ScrollableLink'
import MobileMenu from './MobileMenu'
import styled from 'styled-components'
import css from '@styled-system/css'
import useScroll from '../../helpers/useScroll'
import { Box, Icon, Heading, Text, Flex } from '../UI'
import Flourish from '../Flourish'
import Logo from '../Logo'
import Search from '../Search'
import MenuToggle from './MenuToggle'
import { StaticQuery } from 'gatsby'

const TopMenu = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: height 300ms ease;
  z-index: 99;
  .nav-ul {
    position: absolute;
    left: 0;
    text-align: center;
    list-style-type: none;
    height: 100%;
    width: 100%;
    li {
      height: 100%;
      display: inline-flex;
    }
  }
`
const NavLogo = styled(Box)`
  svg {
    fill: ${props => props.theme.colors.secondary[0]};
  }

  @media screen and (min-width: 700px) {
    svg {
      fill: ${props => props.theme.colors.secondary[0]};
      &:hover {
        fill: ${props => props.theme.colors.secondary[2]};
      }
    }
  }
  .rizzolo-text,
  .guitars-text {
    transition: all 750ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* transform: ${props =>
      props.scrolled ? 'translateX(0)' : 'translateX(-15px)'};

    opacity: ${props => (props.scrolled ? 1 : 0)}; */
  }
  @media screen and (max-width: 700px) {
    .rizzolo-text,
    .guitars-text {
      opacity: 1;
    }
  }
  .guitars-text {
    transition-delay: 175ms;
  }
  &:hover {
    .rizzolo-text,
    .guitars-text {
      opacity: 1;
    }
  }
`

const Start = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    position: absolute;
  }
`
const End = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const links = [
  {
    title: 'Home',
    label: 'home',
    to: '/',
    exact: true,
  },
  {
    label: 'Instruments',
    to: '/instruments',
    subMenu: [
      {
        label: 'Archtop',
        to: '/instruments/type/archtop',
      },
      {
        label: 'Acoustic',
        to: '/instruments/type/acoustic',
      },
      {
        label: 'Electric',
        to: '/instruments/type/electric',
      },
      {
        label: 'Bass',
        to: '/instruments/type/bass',
      },
      {
        label: 'Ukelele',
        to: '/instruments/type/ukelele',
      },
    ],
  },
  {
    label: 'News & Media',
    to: '/news',
  },
  {
    label: 'About',
    to: '/about',
  },
  {
    label: 'Contact',
    to: '/contact',
  },
]
const searchIndices = [
  { name: `Instruments`, title: `Instruments`, hitComp: `InstrumentHit` },
  { name: `Posts`, title: `Articles`, hitComp: `PostHit` },
  { name: `Products`, title: `Products`, hitComp: `ProductHit` },
]
const Nav = React.memo(() => {
  const [open, setOpen] = useState(false)
  // perf kinda bad coupled with home page hero onscroll
  // const [scrolled, setScrolled] = useState(false)

  // const threshold = 250
  // const onScroll = lastPos =>
  //   lastPos > threshold ? setScrolled(true) : setScrolled(false)
  // useScroll(onScroll)

  const toggle = () => setOpen(!open)

  // const icon = open ? 'times' : 'bars'

  return (
    <StaticQuery query={query}>
      {({ MainNav, MobileNav }) => {
        return (
          <Box className="has-dark-background">
            <TopMenu
              // scrolled={scrolled}
              px={{ _: 0, md: 2 }}
              alignItems="center"
              flexDirection="row"
              css={css({
                height: 'navBarHeight',
                backgroundColor: 'primary.0',
                color: 'secondary.1',
              })}
            >
              <Box display={{ _: 'block', lg: 'none' }}>
                <MenuToggle open={open} onClick={toggle} />
                {/*       <Box
            bg={'transparent'}
            color={'white'}
            as="button"
            style={{ border: 'none' }}
            fontSize={1}
            width={52}
            px={1}
            py={1}
            aria-label="Toggle menu"
            onClick={toggle}
          >
            <Icon name={icon} size="1x" />
          </Box> */}
              </Box>
              <Start
                css={css({
                  zIndex: 1,
                  position: ['absolute', 'absolute', 'relative'],
                  left: ['50%', '50%', 0],
                  transform: ['translateX(-50%)', 'translateX(-50%)', 'none'],
                })}
              >
                <ScrollableLink to="/" aria-label="Home">
                  <NavLogo // scrolled={scrolled}
                  >
                    <Logo width={{ _: 125, md: 100, lg: 125 }} />
                  </NavLogo>
                  {/*    <Heading m={0} ml={1} fontFamily="serif">
            Rizzolo Guitars
          </Heading> */}
                </ScrollableLink>
              </Start>
              <Box as="nav" width={1} height={'100%'}>
                <Box
                  className="nav-ul"
                  as="ul"
                  m={0}
                  p={0}
                  display={{ _: 'none', lg: 'block' }}
                >
                  {MainNav.menuItems.map((props, i) => (
                    <MenuItem key={i} {...props} />
                  ))}
                </Box>
              </Box>
              <End />
              <Search collapse indices={searchIndices} />
            </TopMenu>
            <Box display={{ _: 'block', lg: 'none' }}>
              <MobileMenu menu={MobileNav} open={open} toggle={toggle} />
            </Box>
          </Box>
        )
      }}
    </StaticQuery>
  )
})

const query = graphql`
  query Navigation {
    MainNav: contentfulMenu(name: { eq: "Top Menu" }) {
      name
      menuItems {
        label
        path
        subMenu {
          id
          menuItems {
            label
            path
          }
        }
      }
    }
    MobileNav: contentfulMenu(name: { eq: "Mobile Menu" }) {
      name
      menuItems {
        label
        path
        icon
        subMenu {
          id
          menuItems {
            label
            path
          }
        }
      }
    }
  }
`

export default Nav
