import styled from 'styled-components'
import propTypes from '@styled-system/prop-types'
import { lighten } from 'polished'
import {
  space,
  layout,
  color,
  background,
  border,
  borderRadius,
  variant,
  display,
  compose,
} from 'styled-system'
import Text from '../Text'

const buttonProps = compose(
  layout,
  space,
  color,
  border,
  display,
  borderRadius,
  background
)

const Button = styled(Text)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      ![
        'small',
        'borderRadius',
        'lineHeight',
        'flexDirection',
        'hoverColor',
        'bgHoverColor',
        'zIndex',
      ].includes(prop) && !prop.startsWith('_css'),
  })
  .attrs(props => ({
    fontSize: props.small ? 0 : props.large ? 2 : props.fontSize,
    px: props.small ? 2 : props.px,
    py: props.small ? 1 : props.py,
  }))`
  ${variant({
    prop: 'variant',
    scale: 'buttons',
    variants: {
      // can be blank to enable the new API
      primary: {},
    },
  })}
${buttonProps}
  outline: none;
  color: ${props =>
    props.disabled
      ? 'silver'
      : !props.variant && props.color
      ? props.color
      : undefined};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.15s ease-out;
      border: ${props =>
        props.borderColor ? `1px solid ${props.borderColor}` : undefined};
  border-width: ${props => (props.borderColor ? '1px' : '0px')};
  &:hover {
    color: ${props => (props.hoverColor ? `${props.hoverColor}` : undefined)};
    background-color: ${props =>
      props.bgh
        ? lighten(0.25, `${props.theme.colors[props.bg]}`)
        : props.bgHoverColor
        ? `${props.bgHoverColor}`
        : undefined};
    border: ${props =>
      props.borderHoverColor
        ? `1px solid ${props.borderHoverColor}`
        : undefined};
  }
`
Button.defaultProps = {
  variant: 'primary',
  as: 'button',
  px: 3,
  py: 2,
  borderRadius: 3,
  display: 'flex',
}
Button.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.background,
  ...propTypes.border,
  ...propTypes.display,
}
Button.displayName = 'Button'
export default Button
