import React from 'react'
import Card from '../Card'
import Content from '../Content'

const AuthorCard = ({author}) => {
  return (
    <Card
      itemScope
      itemType="http://schema.org/Author"
      borderRadius={1}
      border={'1px solid'}
      borderColor={'lightergrey'}
      backgroundColor="lightergrey"
      boxShadow="none"
      flexDirection="row"
      image={author.image}
      headingProps={{ itemProp: 'name', mb: 'xs', as: 'h4', fontSize: 1 }}
      heading={author.name}
      textProps={{ fontSize: 0, mb: 0 }}
      text={author.title}
    >
      {/*   <Content document={author.bio.json} /> */}
    </Card>
  )
}

export default AuthorCard
