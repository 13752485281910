import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Box,
  Grid,
  Flex,
  Container,
  Text,
  Heading,
  IconButton,
  Label,
  Icon,
} from '../UI'
import Content from '../Content'
import Card from '../Card'
import ImageSlider from '../ImageSlider'
import { PageTransitionSpring } from '../../animation'
import SharingButtons from '../SharingButtons'
import AuthorCard from '../AuthorCard'
import ContentfulEdit from '../ContentfulEdit'

const AbsBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  .card {
    background-attachment: fixed;
  }
  @media screen and (max-width: 950px) {
    left: unset;
    right: unset;
    bottom: unset;
    z-index: unset;
    top: unset;
    position: relative;
    .card {
      background-attachment: unset;
    }
  }
`
function PageLayout({ horizontal, page, image, afterContent, sharing }) {
  const date = page.publishDate ? new Date(page.publishDate) : null
  const publishDate = date ? date.toDateString() : null
  return horizontal ? (
    <Grid cols={{ _: 1, lg: 2 }} rows={{ _: 'auto', lg: 1 }}>
      <PageTransitionSpring
        animation="custom"
        customAnimation={{
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        }}
        style={{ position: 'relative' }}
      >
        <AbsBox
          btrr={{ _: 0, lg: 2 }}
          bbrr={{ _: 0, lg: 2 }}
          mt={{ _: 0, lg: 3 }}
          mb={{ _: 0, lg: 3 }}
          overflow="hidden"
        >
          <Card
            className="card"
            height={{ _: 'auto', lg: '100%' }}
            borderRadius={null}
            color={'white'}
            mx={0}
            backgroundImage={image}
            backgroundSize={'cover'}
            backgroundPosition={{ _: 'top', lg: '50%' }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              display={'flex'}
              p={3}
            >
              <Heading mb={0} fontFamily="serif" fontSize={[4, 5, 52]}>
                {page.heading || page.title}
              </Heading>
              {page.subHeading && (
                <Box mb={2}>
                  <Text fontSize={2} textAlign="center" fontWeight={500}>
                    {page.subHeading}
                  </Text>
                </Box>
              )}
            </Flex>
          </Card>
        </AbsBox>
      </PageTransitionSpring>
      <Container>
        <PageTransitionSpring
          animation="fromBottom"
          delay={100}
          pageName={page.heading || page.title}
          // extraDuration={15000}
        >
          <Box
            py={3}
            mx={{ _: 0, xs: 0, sm: 2, xl: 0 }}
            bg={'white'}
            id="container-before-content"
          >
            {page.slider && (
              <Box pb={3}>
                <ImageSlider
                  images={page.slider.images}
                  settings={{ infinite: false }}
                />
              </Box>
            )}
            <Flex flexWrap="wrap">
              <Box width={1} maxWidth={'articleWidth'} mx={'auto'} fontSize={2}>
                {page.content && <Content document={page.content.json} />}
                <Box pb={2} />
                {afterContent && afterContent()}
              </Box>
            </Flex>
          </Box>
        </PageTransitionSpring>
      </Container>
    </Grid>
  ) : (
    // this is actually just the articles layout (and non-horizontal pages)
    // and should be its own component
    <Box as="article" itemScope itemType="http://schema.org/Article">
      <meta itemProp="image" content={image} />
      {publishDate && (
        <meta itemProp="datePublished" content={date.toISOString()} />
      )}
      <PageTransitionSpring animation="fromBottom">
        <PageHeader page={page} sharing={sharing} image={image} />
      </PageTransitionSpring>
      {/* MAKE THIS LAYOUT A COMPONENT */}
      <PageTransitionSpring
        animation="fromBottom"
        delay={200}
        pageName={page.heading || page.title}
        // extraDuration={15000}
      >
        <ContentfulEdit id={page.contentful_id} />

        <Box>
          <Container
            as="section"
            btlr={{ _: '0px', xs: '0px', sm: 2 }}
            btrr={{ _: '0px', xs: '0px', sm: 2 }}
            pt={3}
            pb={3}
            width={{ _: 1, sm: '90%', xl: 1 }}
            // mx={{ _: 0, xs: 0, sm: 2, xl: 0 }}
            id="container-before-content"
          >
            {page.slider && (
              <Box pb={3} ml={-2} mr={-2}>
                <ImageSlider
                  // imgProps={{
                  //   btlr: { _: '0px', xs: '0px', sm: 2 },
                  //   btrr: { _: '0px', xs: '0px', sm: 2 },
                  // }}
                  thumbs={page.slider.images[0].fixed}
                  images={page.slider.images}
                  settings={{ infinite: false }}
                />
              </Box>
            )}
            <Flex flexWrap="wrap">
              <Box
                // mt={{ _: 2, md: 4 }}
                width={1}
                maxWidth={'articleWidth'}
                mx={'auto'}
                fontSize={2}
              >
                <Box mb={2}>
                  {publishDate && (
                    <Text
                      color={'primary.2'}
                      as="span"
                      fontSize={'xs'}
                      itemProp="date"
                      mb={0}
                      mr={1}
                    >
                      {publishDate}
                    </Text>
                  )}
                  {page.category && (
                    <Label boxProps={{ mb: 0 }}>{page.category.title}</Label>
                  )}
                </Box>
                {page.content && (
                  <Content
                    itemProp="articleBody"
                    document={page.content.json}
                  />
                )}
                {page.author && (
                  <Box my={3}>
                    <AuthorCard author={page.author} />
                  </Box>
                )}
              </Box>
              <Box pb={2} />

              {afterContent && afterContent()}
            </Flex>
          </Container>
        </Box>
      </PageTransitionSpring>
    </Box>
  )
}

PageLayout.propTypes = {
  horizontal: PropTypes.bool,
  page: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  afterContent: PropTypes.func,
  sharing: PropTypes.bool,
}

export default PageLayout

export const PageHeader = ({ page, sharing, image }) => (
  <Box
    bbrr={{ _: 'none', lg: 2 }}
    bblr={{ _: 'none', lg: 2 }}
    ml={{ _: 0, lg: 2, xl: 3 }}
    mr={{ _: 0, lg: 2, xl: 3 }}
    width={{ _: 1, lg: 'calc(100% - 4em)', xl: 'calc(100% - 8em)' }}
    overflow="hidden"
  >
    <Card
      borderRadius={null}
      color={'white'}
      mx={0}
      boxShadow={!image && null}
      backgroundImage={image}
      backgroundColor="secondary.2"
      backgroundPosition={{ _: 'top', lg: '50%' }}
      backgroundSize="cover"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        display={'flex'}
        p={{ _: 1, md: 2 }}
      >
        <Box
          minHeight={{ _: 25, sm: 100, md: 150, xl: 180, xxl: 320 }}
          m="auto"
        />
        <PageTransitionSpring
          animation="fromTop"
          delay={350}
          extraDuration={15000}
        >
          <Box as="header" mb={3} width={1} maxWidth={'articleWidth'}>
            <Heading
              as="h1"
              itemProp="name"
              fontFamily="serif"
              fontSize={[4, 5, 52]}
              mb={1}
              textAlign="center"
              color={image ? 'white' : 'primary.1'}
            >
              {page.heading || page.title}
            </Heading>
            {page.subHeading && (
              <Box mb={2}>
                <Text
                  as="h2"
                  fontSize={2}
                  textAlign="center"
                  fontWeight={500}
                  color={image ? 'white' : 'primary.1'}
                >
                  {page.subHeading}
                </Text>
              </Box>
            )}
            {page.excerpt && (
              <Heading
                as="h3"
                fontSize={2}
                mb={2}
                fontWeight={500}
                textAlign="center"
                color={image ? 'white' : 'primary.1'}
              >
                {page.excerpt.excerpt}
              </Heading>
            )}
            {sharing && (
              <SharingButtons
                title={page.heading || page.title}
                path={`/${page.slug}`}
              />
            )}
          </Box>
        </PageTransitionSpring>
      </Flex>
    </Card>
  </Box>
)
