import React from 'react'
import { Box } from '../UI'

// make true to display a visible edit link

// on live site, use this bookmarklet that creates the link from the hidden div
// javascript: var domEl = document.querySelector('[data-contentful_id]'); var contentful_id = domEl.dataset.contentful_id; if(contentful_id)window.open(`https://app.contentful.com/spaces/bki8s3rufpd7/entries/${contentful_id}`);

const IS_VISIBLE = false

const ContentfulEdit = ({ id }) => {
  const link = `https://app.contentful.com/spaces/bki8s3rufpd7/entries/${id}`
  return IS_VISIBLE ? (
    <Box p={1} data-contentful_id={id} style={{ textAlign: 'center' }}>
      <a href={link} target="_blank">
        Edit
      </a>
    </Box>
  ) : (
    <div data-contentful_id={id} />
  )
}
export default ContentfulEdit
