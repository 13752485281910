import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import { IconButton, Icon } from '../UI'
import Slider from '../Slider'
import PreviewCompatibleImage from '../PreviewCompatibleImg'
import Portal from '../../helpers/Portal'
import useKeyPress from '../../helpers/useKeyPress'

const SlickWrapper = styled.div`
  z-index: 1;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  .slick-slider {
    background-color: ${props => props.theme.colors.lightergrey};
  }
  .slick-track {
    align-items: center;
    display: flex;
    max-height: 100vh;
    background-color: ${props => props.theme.colors.lightergrey};
  }
  &.full-screen {
    .slick-list {
      min-height: 100vh;
    }
    .slick-track {
      min-height: 100vh;
    }
  }
`

const FullSliderImg = styled(PreviewCompatibleImage)`
  max-height: 100vh;
`

const FullScreenSlider = ({
  animatedStyle,
  onClose,
  images,
  currentImageIndex,
}) => {
  const ref = useRef(null)
  const focusSlider = () => {
    ref.current.innerSlider.list.setAttribute('tabindex', 0)
    ref.current.innerSlider.list.focus()
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
  }

  useEffect(() => {
    ref && ref.current && focusSlider()
    return () => (document.getElementsByTagName('body')[0].style.overflow = '')
  }, [])

  const esc = useKeyPress('Escape')
  useEffect(() => {
    esc && onClose()
  }, [esc])


  return (
    <Portal>
      <animated.div
        style={{
          ...animatedStyle,
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: '#fafafa',
          zIndex: 3,
        }}
      >
        <SlickWrapper className="full-screen">
          <Slider
            ref={ref}
            nextArrowProps={{
              // borderRadius: { _: 2, sm: 3 },
              boxShadow: '',
              // px: { _: 2, sm: 2 },
              // py: { _: 1, sm: 2 },
            }}
            prevArrowProps={{
              // borderRadius: { _: 2, sm: 3 },
              boxShadow: '',
              // px: { _: 2, sm: 2},
              // py: { _: 1, sm: 2 },
            }}
            settings={{
              adaptiveHeight: true,
              initialSlide: currentImageIndex,
              // initialSlide: initialSlide,
              // beforeChange: this.handleBeforeChange,
              // asNavFor: thumbsSlider,
              infinite: false,
              arrows: true,
            }}
          >
            {images &&
              images.map(image => (
                <FullSliderImg
                  showLoading
                  key={image.id}
                  objectFit={'contain'}
                  placeHolderStyle={{ backgroundColor: '#dadada' }}
                  // minHeight={700}
                  styles={{
                    // backgroundColor: '#dadada',
                    display: 'block',
                  }}
                  imgStyle={{ objectFit: 'contain', verticalAlign: 'middle' }}
                  imageInfo={{
                    fluid: image.fluid,
                  }}
                />
              ))}
          </Slider>
          <IconButton
            round
            onClick={onClose}
            bg="lightergrey"
            variant="none"
            color="grey"
            name={'times'}
            tabIndex={0}
            aria-label="Close slider"
            style={{
              zIndex: 1,
              position: 'absolute',
              top: 12.5,
              right: 12.5,
            }}
          />
        </SlickWrapper>
      </animated.div>
    </Portal>
  )
}

export default FullScreenSlider
