import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Box, { borderRadiuses } from '../UI/Box'
import {
  width,
  space,
  maxHeight,
  minHeight,
  minWidth,
  maxWidth,
  height,
  flex,
  compose,
} from 'styled-system'
import { Loader } from '../Loader'

const imgProps = compose(
  flex,
  width,
  space,
  maxHeight,
  minHeight,
  minWidth,
  maxWidth,
  height,
  borderRadiuses
)

const ImgBox = styled(Img)(imgProps)

ImgBox.defaultProps = {
  width: 1,
}

const PreviewCompatibleImage = ({
  imageInfo,
  styles,
  imgStyle,
  cover,
  showLoading,
  ...rest
}) => {
  const placeHolderStyle = {}
  const imageStyle = cover
    ? {
        position: 'absolute',
        left: 0,
        top: 0,
        // width: '100%',
        height: '100%',
        ...styles,
        ...placeHolderStyle,
      }
    : {
        // width: '100%',
        // height: 'auto',
        ...styles,
        ...placeHolderStyle,

        // ...placeHolderStyle,

        // maxWidth: 500,
      }

  const { title = '', fluid, fixed, image } = imageInfo

  const [loading, setLoading] = useState(true)

  const startLoading = () => {
    // setLoading(true)
  }
  const endLoading = () => {
    setLoading(false)
  }

  if (!!image && !!image.fluid) {
    return (
      <>
        <ImgBox
          onStartLoad={startLoading}
          onLoad={endLoading}
          {...rest}
          style={imageStyle}
          imgStyle={imgStyle}
          {...placeHolderStyle}
          fluid={image}
          alt={title}
        />
        <Loader show={loading && showLoading} />
      </>
    )
  }

  if (fluid) {
    return (
      <>
        <ImgBox
          onStartLoad={startLoading}
          onLoad={endLoading}
          {...rest}
          style={imageStyle}
          imgStyle={imgStyle}
          placeHolderStyle={placeHolderStyle}
          fluid={fluid}
          alt={title}
        />
        {loading && showLoading && <Loader />}
      </>
    )
  }
  if (fixed) {
    return (
      <>
        <ImgBox
          onStartLoad={startLoading}
          onLoad={endLoading}
          {...rest}
          style={imageStyle}
          imgStyle={imgStyle}
          placeHolderStyle={placeHolderStyle}
          fixed={fixed}
          alt={title}
        />
        {loading && showLoading && <Loader />}
      </>
    )
  }
  if (!!image && typeof image === 'string')
    return (
      <Box {...rest}>
        <img
          className="img-string-src"
          style={imageStyle}
          src={image}
          alt={title}
        />
      </Box>
    )

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.object,
    // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage
