import React, { useState } from 'react'
import Button from './'
import styled from 'styled-components'
import { flexDirection } from 'styled-system'
import Icon from '../Icon'

const Btn = styled(Button)`
  ${flexDirection}
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const IconButton = ({
  round,
  flexDirection,
  name,
  iconSize,
  children,
  transform,
  spin,
  pulse,
  ...rest
}) => {
  const [hover, setHover] = useState(false)
  // return nothing for the icon if a name wasn't provided
  // (useful for a button that shows an icon after an action e.g loading spinner)
  const Icn = name ? (
    <Icon
      key={'icon'}
      name={name}
      children={Boolean(children)}
      flexDirection={flexDirection}
      size={iconSize}
      transform={transform && hover ? transform : 'grow-1'}
      spin={spin}
      pulse={pulse}
    />
  ) : null

  const roundProps = round
    ? {
        fontSize: 16,
        px: 0,
        py: 0,
        width: 40,
        height: 40,
        borderRadius: 999,
      }
    : {}
  const Layout = children ? [Icn, children] : Icn
  return (
    <Btn
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...roundProps}
      flexDirection={flexDirection}
      {...rest}
    >
      {Layout}
    </Btn>
  )
}

IconButton.defaultProps = {
  iconSize: '1x',
  flexDirection: 'row',
}

export default IconButton
