import { useMemo } from 'react'

/**
 *  returns an object of breakpoints and the corresponding values for styled system
 *  in order to give one boolean prop or object of breakpoints with boolean values,
 *  and map it to the specified responsive style properties
 * @param {boolean} | {object} prop
 * @param {object} | {string} | {number} trueVal
 * @param {object} | {string} | {number} falseVal
 * //param {object} | {string} | {number} defaultVal ?
 * @return {object} | {string} | {number}
 */

const getResponsiveProps = (prop, trueVal, falseVal /* defaultVal */) => {
  return useMemo(() => {
    const breakPoints = ['_', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    let obj = {}
    if (typeof prop === 'object') {
      breakPoints.map(breakPoint =>
        prop[breakPoint]
          ? (obj[breakPoint] = trueVal)
          : (obj[breakPoint] = falseVal)
      )

      return obj
    } else {
      return prop ? trueVal : falseVal
    }
  }, prop)
}
export default getResponsiveProps
