import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, to, config } from 'react-spring'
import { TransitionState } from 'gatsby-plugin-transition-link'

export const PageTransitionSpring = ({
  children,
  extraDuration,
  customAnimation,
  animation,
  delay,
  style,
  disabled,
  trigger,
}) => (
  <TransitionState>
    {({ mount, current, transitionStatus }) => {
      const disable =
        typeof disabled === 'string'
          ? current &&
            current.state &&
            current.state.nextPath &&
            current.state.nextPath.startsWith(disabled)
          : disabled
      const ms = current ? current.length * 1000 : 0

      if (typeof mount === 'undefined' && transitionStatus === 'POP') {
      }
      return disable ? (
        <div>{children}</div>
      ) : (
        // can't use hook inside
        // render function so
        // everything is passed to
        // a new component
        <Ani
          trigger={trigger}
          customAnimation={customAnimation}
          mount={
            typeof mount === 'undefined' && transitionStatus === 'POP'
              ? true
              : mount
          }
          disabled={disabled}
          extraDuration={extraDuration}
          transitionStatus={transitionStatus}
          duration={ms}
          animation={animation}
          delay={delay}
          children={children}
          style={style}
        />
      )
    }}
  </TransitionState>
)
PageTransitionSpring.defaultProps = {
  animation: 'fromTop',
  extraDuration: 0,
}
PageTransitionSpring.propTypes = {
  animation: PropTypes.oneOf(['fromTop', 'fromBottom', 'fade', 'custom']),
  customAnimation: PropTypes.object,
  extraDuration: PropTypes.number,
}

export const Ani = ({
  mount,
  duration,
  extraDuration,
  delay,
  transitionStatus,
  animation,
  customAnimation,
  trigger,
  disabled,
  style,
  children,
}) => {
  const [{ value }, set, stop] = useSpring(() => ({
    immediate: false,
    // config: { clamp: false, ...config.default },
    value: 0,
    config: config.slow,
  }))

  useEffect(() => {
    trigger ? set({ value: 1 }) : set({ value: 0 })
    return () => {
      stop()
    }
  }, [trigger])

  const ani1 = {
    opacity: value,
    transform: value
      .to({ range: [0, 1], output: [-35, 0] })
      .to(v => `translateY(${v}px)`),
  }

  const animations = {
    trigger: ani1,
    fromTop: useSpring({
      from: {
        y: -25,
        opacity: 0,
      },
      to: {
        y: mount ? 0 : -25,
        opacity: mount ? 1 : 0,
      },
      // I'm not sure if duration needs
      // to be specified
      // Should be calculated
      // automatically
      // actually it goes in config, and removes spring
      // physics if used, so skipping for now
      //  duration: duration + extraDuration,
      delay,
    }),
    fromBottom: useSpring({
      from: {
        y: 25,
        opacity: 0,
      },
      to: {
        y: mount ? 0 : 25,
        opacity: mount ? 1 : 0,
      },
      //  duration: duration + extraDuration,
      delay,
    }),
    fade: useSpring({
      from: {
        opacity: 0,
      },
      to: {
        opacity: mount ? 1 : 0,
      },
      delay,
    }),
    custom:
      customAnimation &&
      useSpring({
        ...customAnimation,
        from: customAnimation.from,
        to: mount ? customAnimation.to : customAnimation.from,
        delay: mount ? delay : 0,
        //  duration: duration + extraDuration,
      }),
  }

  return disabled ? (
    children
  ) : (
    <animated.div
      style={
        animation === 'custom' || animation === 'trigger'
          ? { ...animations[animation], ...style }
          : {
              opacity: animations[animation].opacity,
              transform:
                animations[animation].y &&
                animations[animation].y.to(y => `translateY(${y}px)`),
              ...style,
            }
      }
    >
      {children}
    </animated.div>
  )
}

Ani.defaultProps = {
  trigger: true,
}
