import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Box, Text, Button } from '../UI'
import Nav from '../Nav'
import Popup from '../Popup'
import ErrorBoundary from '../ErrorBoundary'
import PropTypes from 'prop-types'
import '../../theme/icons'
import Footer from '../Footer'

const Layout = ({ location, children }) => {
  let header
  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <link rel="dns-prefetch" href="https://use.typekit.net/" />
        <link rel="dns-prefetch" href="https://images.ctfassets.net/" />
        {/* this is the rg webfont kit on developer adobe account */}
        <style>{`@import url('https://use.typekit.net/szs4wic.css')`}</style>
        {/*  <link
          as="style"
          rel="preload"
          href="https://use.typekit.net/szs4wic.css"
          onLoad="this.rel='stylesheet'"
        /> */}
        <link rel="stylesheet" href="../" />
      </Helmet>
      <Nav />
      {/* <Popup
        heading="The Rizzolo Guitars book is out now!"
        text={`A photographic book of forty guitars selected from forty years of
        guitarmaking.`}
        buttonLabel="Get the book"
        imgSrc="/rizzolo-book--cover.png"
        to="/product/rizzolo-book"
        popupId="rizzolo-book-202009"
        hiddenRoutes={['/shop', '/product/rizzolo-book']}
        // campaignStart="2020-09-28"
        // campaignEnd="2020-12-31"
      /> */}
      <Box
        as="main"
        id="site-top"
        mt={'navBarHeight' /* the nav height offset */}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {}

export default Layout
