import React, { useEffect } from 'react'
import { useSpring, animated as a, config, useSprings } from 'react-spring'
import styled from 'styled-components'
import { Text, Box, Icon } from '../../UI/'
import MenuItem from '../MenuItem'

const MenuContainer = styled.div`
  position: fixed;
  top: ${props => props.theme.space.navBarHeight}px;
  background-color: ${props => props.theme.colors.primary[1]};
  color: white;
  padding: 0rem;
  height: 100%;
  z-index: 10;
  width: 100%;
  overflow-y: auto;
`
const M = a(MenuContainer)

const MobMenuItem = styled.li`
  > .nav__menu-item {
    min-height: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* background: ${props => props.theme.colors.secondary[1]}; */
    &:hover,
    &:focus {
      /* background: ${props => props.theme.colors.secondary[1]}; */
    }
    a {
      flex: 1;
      width: 100%;
      display: block;
      color: ${props => props.theme.colors.secondary[1]};
      border: none;
      text-decoration: none;
      font-size: 1rem;
      padding-left: 2rem;

      &:active,
      &.active {
        border-bottom-right-radius: 0px;
        color: ${props => props.theme.colors.secondary[1]};
        background: ${props => props.theme.colors.primary[0]};
        ~ button,
        ~ ul {
          color: ${props => props.theme.colors.secondary[1]};
           background: ${props => props.theme.colors.primary[0]};
        }
      }
    }
    button {
      display: block;
      /* padding-right: 2rem; */
      color: ${props => props.theme.colors.secondary[1]};
    }
    .nav__sub-menu {
      margin-top: 1px;
      /* margin-bottom: 1rem; */
      border-radius: 0;
      /* background: ${props => props.theme.colors.secondary[1]}; */

      position: relative;
      padding-top: 0;
      a {
        padding-left: 3rem;
        /* padding-top: 0.5rem;
        padding-bottom: 0.5rem; */
      }
    }
  }
`
const MI = a(MobMenuItem)

const MobileMenu = ({ open, toggle, menu }) => {
  // lets us know when we've pressed a menu item

  const [sidebar, setSidebar, stopSidebar] = useSpring(() => ({
    to: { x: 0 },
    from: { x: -100 },
    // reverse: !open,
    config: config.slow,
  }))
  useEffect(() => {
    setSidebar({
      to: { x: 0 },
      from: { x: -100 },
      reverse: !open,
      // config: config.slow,
    })
  }, [open])

  useEffect(() => {
    return () => {
      stopSidebar()
    }
  }, [])
  // using springs instead of Trail
  // because of an error
  const [MenuItems, setMenuItems] = useSprings(
    menu.menuItems.length,
    menu.menuItems.map((_, i) => ({
      to: { x: 0, opacity: 1 },
      from: { x: -25, opacity: 0 },
      reverse: !open,
      config: config.default,
      delay: i * 175,
    })),
    [open]
  )

  const { x: sidebarX, ...sidebarProps } = sidebar

  return (
    <React.Fragment>
      <M
        as="nav"
        aria-label="Site navigation"
        style={{
          transform: sidebarX.to(x => `translate3d(${x}%,0,0)`),
          visibility: sidebarX.to(x => (x === -100 ? 'hidden' : 'visible')),
          ...sidebarProps,
        }}
      >
        <Box as="ul" py={2}>
          {menu.menuItems.map(({ label, path, icon, ...rest }, index) => {
            const { x, ...props } = MenuItems[index]

            // check if the menu item links to an
            // external website (Gatsby Link shouldn't be used for ext links)
            const external = path.startsWith('http')
            return (
              <MI
                key={index}
                style={{
                  transform: x.to(x => `translate3d(${x}%,0,0)`),
                  ...props,
                }}
              >
                {!external ? (
                  <MenuItem
                    mode={'touch'} // disabled hover sub-menu toggle
                    label={label}
                    path={path}
                    icon={icon}
                    onClick={toggle}
                    target={external ? '_blank' : undefined}
                    href={external ? path : undefined}
                    {...rest}
                  />
                ) : (
                  <Box
                    as={'a'}
                    px={2}
                    py={1}
                    fontSize={2}
                    type="navLink"
                    to={path}
                    {...rest}
                    onClick={toggle}
                    target={external ? '_blank' : undefined}
                    href={external ? path : undefined}
                  >
                    {icon && <Icon name={icon} mr={1} />}
                    <Text as="span" fontWeight="bold">
                      {label}
                    </Text>
                  </Box>
                )}
              </MI>
            )
          })}
        </Box>
      </M>
    </React.Fragment>
  )
}
export default MobileMenu
