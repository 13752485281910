import React from 'react'
import PageLayout from '../PageLayout'
import SEO from '../SEO'
import { Heading, Box, Text, Button, Flex } from '../UI'
import ScrollableLink from '../ScrollableLink'
import { PageTransitionSpring } from '../../animation'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <SEO title="Oops!" />
          <PageTransitionSpring>
            <Box py={4} minHeight={'60vh'}>
              <Flex align="center" flexDirection="column">
                <Heading textAlign="center" mb={1}>
                  Oh no.
                </Heading>
                <Text fontSize={2} mb={3} textAlign="center">
                  There was an unrecoverable error.
                </Text>

                <ScrollableLink to="/">
                  <Button variant="secondary" small>
                    Go back to the home page
                  </Button>
                </ScrollableLink>
              </Flex>
            </Box>
          </PageTransitionSpring>
        </>
      )
    }

    return this.props.children
  }
}
export default ErrorBoundary
