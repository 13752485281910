import React, { Fragment } from 'react'
import styled from 'styled-components'
import {
  Highlight,
  Snippet,
  connectHits,
  connectHighlight,
} from 'react-instantsearch-dom'
import { Link } from 'gatsby'
// import { animated as a, Transition } from 'react-spring'
import { Icon, Label, Heading, Text, Flex, Box } from '../UI'
import PreviewCompatibleImage from '../PreviewCompatibleImg'

const HitBox = styled(Box)`
  a {
    color: ${props => props.theme.colors.secondary[1]};
    > h4 {
      color: ${props => props.theme.colors.tomato};
    }
  }
`
const ExcerptTextProps = {
  fontFamily: 'sans',
  fontSize: 0,
}

export const PageHit = ({ hit, clickHandler }) => (
  <Link to={hit.slug} className="inset-focus" onClick={clickHandler}>
    <Heading m={0} as="h4">
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </Heading>
  </Link>
)
export const PostHit = ({ hit, clickHandler }) => (
  <Link to={`/post/` + hit.slug} className="inset-focus" onClick={clickHandler}>
    <Flex flexDirection="row">
      {hit.featuredImage && (
        <PreviewCompatibleImage
          minWidth={75}
          width={75}
          mr={1}
          height={75}
          imageInfo={hit.featuredImage}
        />
      )}
      <div>
        <Heading mb={'xs'} as="h4">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Heading>
        <div>
          {/*  <Highlight attribute="publishDate" hit={hit} tagName="mark" /> */}

          {/*      <Icon size="1em" />
        &nbsp;
        {hit.tags.map((tag, index) => (
          <Fragment key={tag}>
          {index > 0 && `, `}
          {tag}
          </Fragment>
        ))} */}
        </div>
        <Text {...ExcerptTextProps}>
          <Snippet attribute="excerpt.excerpt" hit={hit} tagName="mark" />
        </Text>
      </div>
    </Flex>
  </Link>
)
const ProductHit = ({ hit, clickHandler }) => {
  return (
    <Link to={`/product/${hit.slug}`}>
      <Flex flexDirection="row">
        {hit.slider && hit.slider.images && hit.slider.images[0] && (
          <PreviewCompatibleImage
            minWidth={75}
            width={75}
            mr={1}
            height={75}
            imageInfo={hit.slider.images[0]}
          />
        )}
        <div>
          <Heading mb={'xs'} as="h4">
            <Highlight
              attribute="stripeProduct.name"
              hit={hit}
              tagName="mark"
            />
          </Heading>
        </div>
      </Flex>
    </Link>
  )
}
const InstrumentHit = ({ hit, clickHandler }) => (
  <Link
    to={`/instrument/` + hit.slug}
    className="inset-focus"
    onClick={clickHandler}
  >
    <Flex flexDirection="row">
      {hit.heroImage && (
        <PreviewCompatibleImage
          minWidth={75}
          width={75}
          mr={1}
          height={75}
          imageInfo={hit.heroImage}
        />
      )}
      <div>
        <Heading mb={'xs'} as="h4">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Heading>
        <Label boxProps={{ py: 3.5, px: 'xs', mb: 'xs' }}>
          {hit.type.title}
        </Label>
        <div>
          <CustomHighlight attribute="meta" hit={hit} tagName="mark" />

          {/*  {hit._highlightResult.meta &&
            hit._highlightResult.meta.map((value, index) => (
             value.matchLevel === 'full' ?
             <Text key={index} dangerouslySetInnerHTML={{__html: value.value}}>
               
              </Text>
              : null
          ))} */}
        </div>
      </div>
    </Flex>
  </Link>
)
const MetaHighlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  })

  return (
    <Text {...ExcerptTextProps}>
      {parsedHit.map((part, index) => {
        const isMatch = part.some(p => p.isHighlighted)
        return (
          isMatch && (
            <span className="parsedHit" key={index}>
              {part.map((match, i) =>
                match.isHighlighted ? (
                  <mark key={i}>{match.value}</mark>
                ) : (
                  <span key={i}>{match.value}</span>
                )
              )}
              {`, `}
            </span>
          )
        )
      })}
    </Text>
  )
}
const CustomHighlight = connectHighlight(MetaHighlight)

const Hits = ({ hits, clickHandler, hitComp }) => {
  const comps = {
    PageHit: PageHit,
    PostHit: PostHit,
    InstrumentHit: InstrumentHit,
    ProductHit: ProductHit,
  }

  const Comp = comps[hitComp]
  return (
    <ul>
      {/* <Transition
        items={hits}
        native
        trail={100}
        keys={item => item.id}
        from={{
          transform: 'translate3d(-2px,0px,0)',
          opacity: 0,
          height: 0,
        }}
        enter={{
          transform: 'translate3d(0px,0px,0)',
          opacity: 1,
          height: 'auto',
        }}
        leave={{
          transform: 'translate3d(-4px,0px,0)',
          opacity: 0,
          height: 0,
        }}
      >
        {item => props => (
          <li>
            <a.div style={props} key={item.key}>
              <HitBox py={1} borderTop="1px solid" borderTopColor="primary.1">
                <Comp hit={item} clickHandler={clickHandler} />
              </HitBox>
            </a.div>
          </li>
        )}
      </Transition> */}
      {hits.map(hit => (
        <li key={hit.id}>
          <HitBox my={1}>
            <Comp hit={hit} clickHandler={clickHandler} />
          </HitBox>
        </li>
      ))}
    </ul>
  )
}

export const CustomHits = connectHits(Hits)
