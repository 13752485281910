import React from 'react'
import { Box } from '../UI'
const Flourish = ({ style, width }) => (
  <Box width={width}>
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 476 283.23"
    >
      <title>Flourish</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path d="M47.91,175c26.57.15,38.73-6.26,50.87-9.74S158.64,137,158.64,137s40.3-21.58,62.76-33.24a472.39,472.39,0,0,1,47.67-20.92A141.6,141.6,0,0,1,318.5,75c56.2,2.92,94,28.59,110.45,42.25s29.54,28.84,43,55.72c5,11,4.46,12.82,3,14-1.2,1.12-3.69-.95-4.7-1.79s-2.41-2.42-6.81-6.49c-17-14.75-39.42-29.62-57-37.12-22.46-11.43-65.39-20.9-106-8.24-46.3,14.43-82.68,42.05-124.62,66.83-43.93,26.86-79.32,31.76-108.66,23-30.5-10.78-52.41-33.46-59.34-43.15S0,164.55,0,164.55s0-2,3.08-1.33C12.94,168.59,24.6,174.3,47.91,175Z" />
            <path d="M429,195.38s3.69-1.35-7.56-6.89S390,174.88,361.28,174,311.55,185,292.9,199.41s-46.38,37-65,44.53-29.07,8.9-47.55.67c-3.19-1.35-1.85,3.19-1.85,3.19s0,4.71,13.78,17.81c13.78,12.12,32.43,17.62,46.54,17.62s35.46-7.82,56.29-25.85c22.82-19.47,34.45-29.74,46.88-39.82s33.88-19.79,55-20.85c18.21-.62,22.53-.22,25-.22S427.7,196.27,429,195.38Z" />
            <path d="M441.8,93.7c-.86-1.92-22.92-19.69-32.24-44.47-3.59-9.18-3.21-15.94-.13-21.91,2.74-5.29,7.63-9.43,12.21-10.7,3-.9-3.05-5.45-5.05-6.3-6.86-4-14.89-9.71-31-10.29a21,21,0,0,0-20.08,11.46S359,22.77,371,38.41c9.62,12.06,30.64,32.91,46.82,44.3,15.68,10.59,20.75,12.88,22.33,12.88C441.29,95.59,442.67,95.61,441.8,93.7Z" />
          </g>
        </g>
      </g>
    </svg>
  </Box>
)

export default Flourish
