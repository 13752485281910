import React, { useState, createRef } from 'react'
import {
  InstantSearch,
  Index,
  Configure,
  // Hits,
  Pagination,
  connectStateResults,
  connectHits,
} from 'react-instantsearch-dom'
import styled from 'styled-components'
import css from '@styled-system/css'
import algoliasearch from 'algoliasearch/lite'
import useClickOutside from '../../helpers/useClickOutside'
import Input from './Input'
import { CustomHits } from './hitComps'
import { Icon, Text, Box, Heading, IconButton, AniBox } from '../UI'
import { useSpring, animated as a, config } from 'react-spring'

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const RESULTS_PER_PAGE = 4

// wrap client in a proxy to return mocked result when
// less than 3 chars entered in search input
const searchClient = {
  search(requests) {
    if (
      requests.every(({ params }) => !params.query || params.query.length < 3)
    ) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}
// return the results or no results text
const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) => {
    return children(state, res)
    // if (!state.query) return ''
    // else if (res && res.nbHits > 0) return children
    // else return `No results for '${state.query}'`
  }
)
// display loading text
const Loading = connectStateResults(({ isSearchStalled }) => {
  return isSearchStalled ? 'loading...' : null
})
// print the number of results
const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)
// show the pagination if there are more results than will fit on one page
const Pager = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > RESULTS_PER_PAGE && <Pagination />
)

const SearchToggle = styled(IconButton)``

const Root = styled.div`
  *::-webkit-scrollbar {
    display: none;
  }
  z-index: -1;
  left: 0;
  position: relative;
  display: grid;
  color: ${props => props.theme.textColor};
  position: absolute;
  top: 0;
  width: 100%;
`
const HitsWrapper = styled(Box)`
  display: ${props => (props.show ? `grid` : `none`)};
  max-height: calc(100vh - ${props => props.theme.space.navBarHeight}px);
  overflow-y: scroll;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: 0;
  top: 100%;
  > * + * {
    border-top: 2px solid ${props => props.theme.colors.primary[1]};
  }
  ul {
    margin-left: 0;
    padding: 0;
    text-align: left;
    list-style: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-height: 500px;
    overflow-y: auto;
    li {
      &:hover {
        a {
          color: ${props => props.theme.colors.secondary[3]};
        }
      }
    }
  }
  mark {
    color: ${props => props.theme.colors.secondary[3]};
    background: ${props => props.theme.colors.primary[1]};
  }
  header {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    h3 {
      color: white;
    }
  }
  .ais-Pagination-list {
    display: flex;
    ${css({
      fontFamily: 'sans',
      fontWeight: 500,
      fontSize: 2,
    })}
  }
  .ais-Pagination-link {
    padding: 1rem;
    display: inline-block;
  }
  .ais-Pagination-link--selected {
    ${css({
      color: 'secondary.2',
      fontWeight: 700,
    })}
  }
`

const PoweredBy = () => (
  <Text>
    Powered by{` `}
    <a href="https://algolia.com">
      <Icon name={['fab', 'algolia']} /> Algolia
    </a>
  </Text>
)

const Search = React.memo(({ indices, collapse, hitsAsGrid }) => {
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  const [expand, setExpand] = useState(false)

  // for some reason this isn't working - the ref is being lost
  // const ref = createRef()
  // useClickOutside(ref, closeSearch, !expand)

  const closedStyles = {
    input: 'translate3d(0px,-75px,0px)',
    opacity: 0,
    search: 'translate3d(0px,0px,0px)',
    close: 'translate3d(0px,25px,0px)',
  }
  const openStyles = {
    input: `translate3d(0px,0px,0px)`,
    opacity: 1,
    search: `translate3d(0px,-15px,0px)`,
    close: `translate3d(0px,0px,0px)`,
  }
  const [{ input, opacity, search, close }, set, stop] = useSpring(
    () => closedStyles
  )

  const openSearch = () => {
    setExpand(true)
    setFocus(true)
    set(openStyles)
  }
  const closeSearch = () => {
    setExpand(false)
    setFocus(false)
    set(closedStyles)
  }
  // const { input, opacity, close, search } = useSpring({
  //   from: {
  //     input: 'translate3d(0px,0px,0px)',
  //     opacity: 0,
  //     search: 'translate3d(0px,0px,0px)',
  //     close: 'translate3d(0px,0px,0px)',
  //   },
  //   to: {
  //     input: expand ? `translate3d(0px,0px,0px)` : `translate3d(0,-75px,0px)`,
  //     opacity: expand ? 1 : 0,
  //     search: expand
  //       ? `translate3d(0px,-15px,0px)`
  //       : `translate3d(0px,0px,0px)`,
  //     close: expand ? `translate3d(0px,0px,0px)` : `translate3d(0px,25px,0px)`,
  //   },
  // })

  // const { results, resultsHeight } = useSpring({
  //   from: {
  //     results: 'translate3d(0px,0px,0)',
  //     resultsHeight: 0,
  //   },
  //   to: {
  //     results: `translate3d(${query && query.length > 0 && focus ? 0 : -25}px,${
  //       query && query.length > 0 && focus ? 0 : -75
  //     }px,0)`,
  //     resultsHeight: query && query.length > 0 && focus ? 500 : 0,
  //   },
  //   delay: 200,
  //   config: config.slow,
  // })

  return (
    <React.Fragment>
      <AniBox
        css={css({
          right: [1, 1, 1, 1, 2],
        })}
        style={{
          position: 'absolute',
          transform: search,
          visibility: !expand ? 'visible' : 'hidden',

          opacity: opacity.to([0, 1], [1, 0]),
          zIndex: 2,
        }}
      >
        <SearchToggle
          onClick={openSearch}
          round
          p={1}
          bg="primary.0"
          name={['fa', 'search']}
          aria-label="Search"
        />
      </AniBox>

      <Root
        // ref={ref}
        style={{ zIndex: expand ? '1' : '-1' }}
      >
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) =>
            // this is stupid (and really it's just a flag so it works fine)
            // but setting the actual
            // query here was sending 2 queries
            // to the server per change
            query && query.length ? setQuery('query') : setQuery('')
          }
        >
          <Configure hitsPerPage={RESULTS_PER_PAGE} distinct />
          <a.div style={{ transform: input, opacity }}>
            <Input onFocus={() => setFocus(true)} {...{ focus, expand }} />
          </a.div>
          <a.div style={{ transform: input }}>
            <HitsWrapper
              boxShadow={'small'}
              maxWidth={{ _: '100%', md: 550 }}
              width={'100%'}
              fontFamily="sans"
              bg="primary.0"
              bbrr={{ _: 0, md: 2 }}
              // style={{ height: resultsHeight }}
              show={query && query.length > 0 && focus}
              // show={true}
              asGrid={hitsAsGrid}
            >
              <Box p={2}>
                <Loading />
                {indices.map(({ name, title, hitComp }) => (
                  <Index key={name} indexName={name}>
                    <header>
                      <Heading my={0} fontFamily="sans" as="h3">
                        {title}
                      </Heading>
                      <Text color="white">
                        <Stats />
                      </Text>
                    </header>
                    <Box>
                      <Results>
                        {(state, res) => {
                          if (!state.query) return ''
                          if (!res) return ''
                          if (res.nbHits > 0)
                            return (
                              <CustomHits
                                hitComp={hitComp}
                                clickHandler={closeSearch}
                              />
                            )
                          else
                            return (
                              <Text>{`No results for '${state.query}'`}</Text>
                            )
                        }}

                        {/*  <Hits
                          hitComponent={hitComps[hitComp](() =>
                            setFocus(false)
                          )}
                        /> */}
                      </Results>
                      <Pager />
                    </Box>
                  </Index>
                ))}

                <PoweredBy />
              </Box>
            </HitsWrapper>
          </a.div>
        </InstantSearch>
      </Root>
      <AniBox
        css={css({
          right: 2,
        })}
        style={{
          position: 'absolute',
          transform: close,
          opacity: opacity,
          zIndex: 2,
          visibility: expand ? 'visible' : 'hidden',
        }}
      >
        <SearchToggle
          onClick={closeSearch}
          round
          p={1}
          name={['fa', 'times']}
          style={{ zIndex: 2 }}
          aria-label="Close search"
        />
      </AniBox>
    </React.Fragment>
  )
})

export default Search
