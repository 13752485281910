import React, { useEffect } from 'react'
import { useSpring, animated, config, to } from 'react-spring'
import styled from 'styled-components'
import { Box, Flex } from '../../UI'
const Hamburger = styled(Flex)`
  background: none;
`
const Line = styled(animated.span)`
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  /* position: absolute; */
  background-color: #fff;
  &:before {
    content: '';
  }
`
const MenuToggle = ({ open, onClick }) => {
  useEffect(() => {
    open ? setOpenStyles() : setInitStyles()
  }, [open])
  // stop animation on unmount (link pressed)
  useEffect(() => {
    setInitStyles(true)
    return () => {
      stopAnimations()
    }
  }, [])

  const setInitStyles = immediate => {
    const init = immediate
      ? {
          immediate: true,
          ...initialConfig,
        }
      : initialConfig
    setBottom(init)
    setTop(init)
    setCenter(init)
  }
  const setOpenStyles = () => {
    setBottom(openConfig)
    setTop(openConfig)
    setCenter(openConfig)
  }
  const stopAnimations = () => {
    stopCenter()
    stopTop()
    stopBottom()
  }
  // const openedTransformationConfig = {
  //   top: [0, 3, 0, 0, 0, 0],
  //   center: [0, 13.5, 0, 0],
  //   bottom: [0, 24, 0, 0],
  // }

  const initialConfig = {
    top: [0, 3, 0, 0, 0, 0],
    center: [0, 13.5, 0, 0],
    bottom: [0, 24, 0, 0],
  }
  const openConfig = {
    bottom: [0, 15, 0, -45],
    top: [0, 15, 0, 150, 0, 45],
    center: [0, 15, 0, 45],
  }

  const [{ bottom }, setBottom, stopBottom] = useSpring(() => ({
    bottom: [0, 24, 0, 0],
    delay: 100,
  }))

  const [{ top }, setTop, stopTop] = useSpring(() => ({
    top: [0, 3, 0, 0, 0, 0],
    config: config.gentle,
    delay: 0,
  }))

  const [{ center }, setCenter, stopCenter] = useSpring(() => ({
    center: [0, 13.5, 0, 0],
    config: config.gentle,
    delay: 50,
  }))

  return (
    <Hamburger
      align="center"
      onClick={onClick}
      bg={'transparent'}
      color={'white'}
      as="button"
      style={{ border: 'none' }}
      fontSize={1}
      mx={1}
      p={0}
      // width={45}
      // height={45}
      aria-label="Toggle site menu"
      aria-haspopup="true"
      aria-expanded={open}
    >
      <Box display={'block'} position={'relative'} width={30} height={30}>
        <Line
          style={{
            transform: top.to(
              (x, y, z, p, ry, r) =>
                `translate3d(${x}px,${y}px,${z}px) perspective(${p}px) rotateY(${ry}deg) rotate(${r}deg)`
            ),
          }}
        />
        <Line
          style={{
            transform: center.to(
              (x, y, z, r) =>
                `translate3d(${x}px,${y}px,${z}px) rotate(${r}deg)`
            ),
          }}
        />
        <Line
          style={{
            transform: bottom.to(
              (x, y, z, r) =>
                `translate3d(${x}px,${y}px,${z}px) rotate(${r}deg)`
            ),
          }}
        />
      </Box>
    </Hamburger>
  )
}

export default MenuToggle
