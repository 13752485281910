import { useEffect, useCallback, useRef } from 'react'
var debounce = function(func, execAsap) {
  var timeout

  return function debounced() {
    var obj = this,
      args = arguments
    function delayed() {
      if (!execAsap) func.apply(obj, args)
      timeout = null
    }

    if (timeout) cancelAnimationFrame(timeout)
    else if (execAsap) func.apply(obj, args)

    timeout = requestAnimationFrame(delayed)
  }
}

const useScroll = onScroll => {
  let last_known_scroll_position = 0
  const threshold = 250
  const reqRef = useRef()

  const scrollHandler =
    typeof window !== 'undefined' &&
    useCallback(
      e => {
        return debounce(onScroll(window.scrollY))
      },
      [window.scrollY]
    )

  // useEffect(() => {
  //   debounce(scrollHandler)
  //   // return () => cancelAnimationFrame(reqRef.current)
  // }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])
}

export default useScroll
