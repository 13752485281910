import { useEffect, useCallback } from 'react'

const useClickOutside = (ref, handler, disabled, events) => {
  if (!events) events = [`mousedown`, `touchstart`]
  const detectClickOutside = event =>
    ref.current && !ref.current.contains(event.target) && handler()

  useEffect(() => {
    if (disabled)
      return () => {
        for (const event of events)
          document.removeEventListener(event, detectClickOutside)
      }
    for (const event of events)
      document.addEventListener(event, detectClickOutside)

    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  }, [])
}

export default useClickOutside
